import { Box, Flex, HStack, IconButton, Text } from "@wiretronic/iris";
import { MdVisibility } from "react-icons/md";
import "../styles/content.css";
import { Template } from "./BoxEditorAndViewerFunctionality";
interface Props {
  setBoxName(boxName: string, numCells: number): void;
  setTemplate(template: Template): void;
  template: Template;
  index: number;
  _setLargerViewInfo(img: string, artNr: string, e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  onOpenProductModal(): void;
  activeInd: number | undefined;
  setActiveInd(ind: number | undefined): void;
  filteredTemplates: Template[];
}

function BoxTemplateItemSelector({
  setBoxName,
  setTemplate,
  template,
  index,
  _setLargerViewInfo,
  onOpenProductModal,
  activeInd,
  setActiveInd,
  filteredTemplates,
}: Props) {
  return (
    <HStack
      justifyContent={"space-between"}
      width="auto"
      bg={activeInd === index ? "indigo.50" : ""}
      p={4}
      borderRadius="lg"
    >
      <Flex gap={4} align="center">
        <Box w={16} h={16}>
          <img width="auto" height="100%" src={template.image} alt={template.name}></img>
        </Box>
        <Text
          size="md"
          onClick={() => {
            if (activeInd === index) {
              setTemplate({ name: "", image: "", nrRows: 0, nrColumns: 0, cells: [] });
            } else {
              setTemplate(filteredTemplates[index]);
            }
            setActiveInd(index);
          }}
          cursor="pointer"
        >
          {template.name}
        </Text>
      </Flex>
      <IconButton
        variant="tertiary"
        aria-label="Show"
        icon={<MdVisibility />}
        onClick={(e) => {
          _setLargerViewInfo(template.image, template.name, e);
          onOpenProductModal();
        }}
      />
    </HStack>
  );
}

export default BoxTemplateItemSelector;
