import { Login } from "@wiretronic/iris";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../User";
import { login } from "../login";

export default function LoginPage() {
  const { setUser } = useContext(UserContext);
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const err = "Incorrect email & password combination";
  const navigate = useNavigate();

  const handleSubmit = async (email: string, password: string) => {
    setIsLoading(true);
    login(email, password)
      .then((user) => {
        if (user) {
          setUser(user.userName, user.token);
          navigate("/");
        } else {
          setError(err);
        }
      })
      .catch(() => setError(err))
      .finally(() => setIsLoading(false));
  };

  return <Login onSubmit={handleSubmit} error={error} isLoading={isLoading} />;
}
