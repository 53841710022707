import { SlideFade } from "@chakra-ui/react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
  useToast,
} from "@wiretronic/iris";
import React, { useContext, useEffect, useState } from "react";
import { MdAddCircle, MdCancel, MdDone, MdEdit, MdMyLocation } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import uuid from "react-uuid";
import { BoxTemplate, saveBoxesToUGD } from "../BackendConnections";
import { UserContext } from "../User";
import "../styles/content.css";
import { Template } from "./BoxEditorAndViewerFunctionality";
import BoxTemplateSelector from "./BoxTemplateSelector";
import CellEditingPane from "./cellEditor/CellEditingPane";
import ViewCellContentPane from "./cellEditor/ViewCellContentPane";
import GenerateBoxGrid from "./GenerateBoxGrid";
interface Props {
  showAddDiv: boolean;
  activeCell: number | undefined;
  setActiveCell(index: number | undefined): void;
  setCellContent(id: number | undefined, img: string | undefined, artNr: string, quantity: number | undefined): void;
  deleteCellContentEntry(ind: number | undefined): void;
  changeQuantity(index: number, updateFn: (old: number) => number): void;
  handleInput(value: number): void;
  itemSearch: string;
  handleItemSearch(e: React.ChangeEvent<HTMLInputElement>): void;
  existsInCell: number[];
  checkContains(): void;
  onOpen(): void;
  _setLargerViewInfo(img: string, artNr: string): void;
  onOpenProductModal(): void;
  boxTemplate: string;
  setBoxName(boxName: string, numCells: number): void;
  setTemplate(template: Template): void;
  template: Template;
  setShowAddDiv(e?: boolean): void;
  setDate(date?: string): void;
  date: string;
  setLocation(location: string): void;
  location: string;
  isInEditMode?: boolean;
  syncEdit(): void;
  setLabel(label: string): void;
  label: string;
  locationName: string;
}

function BoxEditorAndViewer({
  showAddDiv,
  activeCell,
  setActiveCell,
  setCellContent,
  deleteCellContentEntry,
  changeQuantity,
  handleInput,
  itemSearch,
  handleItemSearch,
  existsInCell,
  checkContains,
  onOpen,
  _setLargerViewInfo,
  onOpenProductModal,
  boxTemplate,
  setBoxName,
  setTemplate,
  template,
  setShowAddDiv,
  setDate,
  date,
  setLocation,
  location,
  isInEditMode,
  syncEdit,
  setLabel,
  label,
  locationName,
}: Props) {
  const [isInCellEditMode, setIsInCellEditMode] = useState(false);
  const [isEditingLabel, setIsEditingLabel] = useState(false);

  const popover = useDisclosure();

  const navigate = useNavigate();

  const { token } = useContext(UserContext);

  const labelEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setLabel(value);
  };

  const currentDate = new Date();
  const hours = currentDate.getHours() < 10 ? `0${currentDate.getHours()}` : currentDate.getHours();
  const minutes = currentDate.getMinutes() < 10 ? `0${currentDate.getMinutes()}` : currentDate.getMinutes();
  const dayHoursMinutesYear = `${currentDate.toISOString().slice(0, 10)} ${hours}:${minutes}`;

  const [showGrid, setShowGrid] = useState(false);

  const toast = useToast();

  const saveBoxesToUgdAndAlert = async (token: string | null, data: BoxTemplate[], locationKey: string) => {
    try {
      const response = await saveBoxesToUGD(token, data, locationKey, true);
      if (response!.status === 200) {
        toast({
          title: "Box added",
          description: `Box was successfully added to ${location} storage`,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      } else {
        toast({
          title: "Error",
          description: `An error has occurred when attempting to add box ${label} to ${location} storage`,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const appendBoxToSavedBoxes = (token: string | null, data: BoxTemplate, locationKey: string) => {
    saveBoxesToUgdAndAlert(token, [data], locationKey);
    setIsInCellEditMode(false);
    setActiveCell(undefined);
  };

  useEffect(() => {
    if (template.name !== "") {
      setShowGrid(true);
    }
  }, [template]);

  return (
    <>
      <Flex p={6} align="center" w="full" justify="space-between">
        <Box>
          <Heading size="sm" color="gray.600" fontWeight="medium">
            <Flex align="center" gap={2}>
              <MdMyLocation />
              {locationName}
            </Flex>
          </Heading>
          <Box h={12}>
            {isEditingLabel ? (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setIsEditingLabel(false);
                }}
              >
                <Flex gap={4} align="center" mt={2}>
                  <Input placeholder="test" value={label} onChange={(e) => labelEdit(e)} />
                  <Button onClick={() => setIsEditingLabel(false)} variant="outline">
                    Save
                  </Button>
                </Flex>
              </form>
            ) : (
              <Flex mt={2} gap={4} align="flex-end">
                <Heading>{label}</Heading>
                <IconButton
                  variant="tertiary"
                  aria-label="Edit"
                  icon={<Icon as={MdEdit} />}
                  onClick={() => {
                    setIsEditingLabel(true);
                  }}
                />
              </Flex>
            )}
          </Box>
        </Box>
        <Flex gap={4} align="center">
          {isInEditMode ? (
            <Button leftIcon={<MdDone />} onClick={syncEdit}>
              Finish editing
            </Button>
          ) : (
            <Popover isOpen={popover.isOpen} onClose={popover.onClose} closeOnEsc>
              <PopoverTrigger>
                <Button leftIcon={<MdAddCircle />} variant="primary" onClick={popover.onOpen}>
                  Add new box
                </Button>
              </PopoverTrigger>
              <PopoverContent minWidth={320}>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Confirmation!</PopoverHeader>
                <PopoverBody>
                  <BoxTemplateSelector
                    setBoxName={setBoxName}
                    setTemplate={setTemplate}
                    _setLargerViewInfo={_setLargerViewInfo}
                    onOpenProductModal={onOpenProductModal}
                    setShowAddDiv={setShowAddDiv}
                    setDate={setDate}
                    location={location}
                    setLocation={setLocation}
                    setLabel={setLabel}
                    label={label}
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover>
          )}
          {template.name && (
            <Button
              leftIcon={<Icon as={MdCancel} color="red.700" />}
              variant="outline"
              onClick={() => {
                setTemplate({ name: "", image: "", nrRows: 0, nrColumns: 0, cells: [] });
                setActiveCell(undefined);
                setIsInCellEditMode(false);
                setShowGrid(false);
                if (isInEditMode) {
                  navigate(`/location/${location}`);
                }
              }}
            >
              Cancel editing
            </Button>
          )}
        </Flex>
      </Flex>
      <Flex>
        <SlideFade in={template.name !== ""} unmountOnExit={true}>
          {!isInEditMode && (
            <Button
              leftIcon={<MdDone style={{ fontSize: "1.5rem" }} />}
              bg="var(--chakra-colors-indigo-700);"
              color={"white"}
              onClick={() => {
                appendBoxToSavedBoxes(
                  token,
                  {
                    id: uuid(),
                    label: label !== "" ? label : "default",
                    startingDate: date,
                    finishDate: dayHoursMinutesYear,
                    template: template,
                  },
                  location
                );
                setShowGrid(false);
                setTemplate({ ...template, name: "" });
                setLabel("default");
              }}
            >
              Finish editing
            </Button>
          )}
        </SlideFade>
      </Flex>
      <SlideFade unmountOnExit={true} in={showGrid}>
        <GenerateBoxGrid
          activeCell={activeCell}
          boxName={boxTemplate}
          setActiveCell={setActiveCell}
          existsInCell={existsInCell}
          setIsInCellEditMode={setIsInCellEditMode}
          template={template}
        />
      </SlideFade>

      <Modal
        isOpen={activeCell !== undefined}
        motionPreset="slideInRight"
        onClose={() => {
          setActiveCell(undefined);
        }}
        size="5xl"
        scrollBehavior={isInCellEditMode ? undefined : "inside"}
      >
        <ModalOverlay />
        <ModalContent position="fixed" right={8} top={8} bottom={8} my={0}>
          <ModalHeader>Viewing cell {(activeCell || 0) + 1}</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflow={isInCellEditMode ? "hidden" : "auto"}>
            {isInCellEditMode ? (
              <CellEditingPane
                activeCell={activeCell}
                setCellContent={setCellContent}
                handleInput={handleInput}
                setIsInCellEditMode={setIsInCellEditMode}
              />
            ) : (
              <ViewCellContentPane
                activeCell={activeCell}
                deleteCellContentEntry={deleteCellContentEntry}
                changeQuantity={changeQuantity}
                itemSearch={itemSearch}
                handleItemSearch={handleItemSearch}
                checkContains={checkContains}
                onOpen={onOpen}
                _setLargerViewInfo={_setLargerViewInfo}
                onOpenProductModal={onOpenProductModal}
                template={template}
                setIsInCellEditMode={setIsInCellEditMode}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default BoxEditorAndViewer;
