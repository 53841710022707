import { Box, Center, Heading, HStack, ListItem } from "@wiretronic/iris";
import { ReactNode } from "react";
import { getStringValue } from "../../lib/node";
import Setting from "../../lib/setting";
import { Node } from "../../interfaces";
import "../../styles/content.css";

type ProductListItemProps = {
  element: Node;
  url: string;
  selectItem(id: number | undefined, img: string | undefined, artNr: ReactNode, quantity: number | undefined): void;
};

const ProductThumbnail = (props: ProductListItemProps) => {
  const thumbnail = getStringValue("thumbnail", props.element.attributes);
  if (thumbnail === undefined) {
    return <Box w={"60px"} h={"60px"} />;
  } else {
    return (
      <Center w={"60px"} h={"60px"}>
        <img width={"30px"} height={"30px"} src={thumbnail} alt="thumbnail"></img>
      </Center>
    );
  }
};

export const ProductListItem = (props: ProductListItemProps) => {
  const articleNumberSetting = new Setting("article_number_key");

  return (
    <ListItem
      cursor="pointer"
      _hover={{
        background: "gray.100",
      }}
      transition="background 120ms"
      key={props.element.id}
      borderBottomWidth={1}
      onClick={() =>
        props.selectItem(
          props.element.id,
          getStringValue("thumbnail", props.element.attributes),
          props.element.attributes[articleNumberSetting.getStoredValue()] as ReactNode,
          1
        )
      }
    >
      <HStack h={"60px"}>
        <ProductThumbnail {...props} />
        <Heading size={"md"}>{props.element.attributes[articleNumberSetting.getStoredValue()] as ReactNode}</Heading>
      </HStack>
    </ListItem>
  );
};
