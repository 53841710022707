import React from "react";
import { Tr, Td, Badge } from "@wiretronic/iris";

import { CellContent } from "./BoxEditorAndViewerFunctionality";
import "../styles/content.css";

interface Props {
  cellContent: CellContent[];
}

export default function RenderCellContentTable({ cellContent }: Props) {
  if (cellContent === undefined || cellContent.length === 0) {
    return <></>;
  }
  const cellContentElements = cellContent.map(function (elem: CellContent, index: number) {
    return (
      <Tr key={index}>
        <Td overflowWrap="break-word" whiteSpace="normal" px={4}>
          <Badge colorScheme="indigo" mr={2}>
            {elem.quantity}
          </Badge>
          {elem.artNr}
        </Td>
      </Tr>
    );
  });
  return <>{cellContentElements}</>;
}
