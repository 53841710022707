import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Grid, Heading, Text, Image } from "@wiretronic/iris";
import { useContext, useEffect, useState } from "react";
import { MdChevronRight } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { BoxTemplate, getBoxesFromUGD } from "../BackendConnections";
import { UserContext } from "../User";
import "../styles/BoxTemplates.css";
import "../styles/content.css";
import { defaultExtractionToolsBoxAspectRatio } from "./Location";
import { InventoryData } from "./BoxEditorAndViewerFunctionality";

interface Props {
  searchQuery: string;
  onSelectResult: () => void;
}

interface BoxResults {
  box: BoxTemplate;
  id: string;
  label: string;
  results: number;
  artNrs: string[];
}

const ResultItem = (
  location: string,
  locationName: string,
  nrResults: number,
  boxResults: BoxResults[],
  onSelectResult: () => void
) => {
  const SearchResultGrid = (box: BoxTemplate) => {
    const cells: any[] = [];
    box.template.cells.forEach((cell, i) => {
      const bgColor = `gray.${Math.max(1, Math.min(7, cell.content.length + 1)) * 100}`;
      box.template.coordinateBasedLayout
        ? cells.push(
            <Box
              key={i}
              position="absolute"
              left={`${cell.left! * 100}%`}
              top={`${cell.top! * 100}%`}
              right={`${cell.right! * 100}%`}
              bottom={`${cell.bottom! * 100}%`}
              backgroundColor={cell.containsArtNr ? "#4263eb" : bgColor}
              transform={`skew(${cell.skewXAxis!}deg)`}
              _groupHover={{
                backgroundColor: cell.containsArtNr ? "#4263eb" : "gray.400",
              }}
              borderRadius="md"
            ></Box>
          )
        : cells.push(
            <Box
              gridColumn={`span ${cell.colSpan} / auto`}
              gridRow={`span ${cell.rowSpan} / auto`}
              backgroundColor={cell.containsArtNr ? "#4263eb" : bgColor}
              _groupHover={{
                backgroundColor: cell.containsArtNr ? "#4263eb" : "gray.400",
              }}
              borderRadius="md"
              key={i}
            />
          );
    });
    return box.template.coordinateBasedLayout ? (
      <Box
        w="125px"
        className={box.template.name}
        position="relative"
        style={{ aspectRatio: box.template.aspectRatio ?? defaultExtractionToolsBoxAspectRatio }}
      >
        <Image src={box.template.image} />
        {cells}
      </Box>
    ) : (
      <div
        className={box.template.name}
        style={{ boxSizing: "border-box", rowGap: "2px", columnGap: "2px", aspectRatio: 1.414 }}
      >
        {cells}
      </div>
    );
  };

  return (
    <Flex flexDirection="column" w="full" style={{ width: "100%", justifyContent: "flex-start" }} gap={6}>
      {boxResults.map((boxResults, key) => {
        const uniqueArticles = Array.from(new Set(boxResults.artNrs));

        return (
          <Box key={key}>
            <Breadcrumb separator={<MdChevronRight />} px={2}>
              <BreadcrumbItem>
                <BreadcrumbLink as={NavLink} to={`/location/${location}`} color="gray.600" onClick={onSelectResult}>
                  {locationName}
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink as={NavLink} to={`/preview/${boxResults.id}`} onClick={onSelectResult}>
                  {boxResults.label}
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>

            <Flex
              role="group"
              p={2}
              _hover={{ background: "gray.100" }}
              key={key}
              as={NavLink}
              to={`/preview/${boxResults.id}`}
              onClick={onSelectResult}
            >
              <Flex justifyContent={"center"} w={56}>
                {SearchResultGrid(boxResults.box)}
              </Flex>
              <Box flex={2} mx={4}>
                <Heading fontSize="md">Matching Articles</Heading>
                <Grid templateColumns="1fr 1fr">
                  {uniqueArticles.slice(0, 7).map((artNr, key) => {
                    return <Text key={key}>{artNr}</Text>;
                  })}
                  {boxResults.artNrs.length > 7 && (
                    <Text color="gray.600" onClick={onSelectResult}>{`...${uniqueArticles.length - 7} more`}</Text>
                  )}
                </Grid>
              </Box>
            </Flex>
          </Box>
        );
      })}
    </Flex>
  );
};

function SearchResults({ searchQuery, onSelectResult }: Props) {
  const [inventoryData, setInventoryData] = useState<InventoryData>();

  const { token, logout } = useContext(UserContext);

  useEffect(() => {
    getBoxesFromUGD(token)
      .then((savedBoxes) => {
        setInventoryData(savedBoxes);
      })
      .catch((reason) => {
        console.log(reason);
        logout();
      });
  }, [searchQuery, logout, token]);

  const Search = (searchQuery: string) => {
    if (inventoryData && searchQuery !== "") {
      const results = Object.entries(inventoryData).map(([key, locationAndBoxes]) => {
        let boxResults: BoxResults[] = [];
        let nrResultsPerLocation: number = 0;
        locationAndBoxes.boxes.forEach((box) => {
          let nrResultsCurrentBox: number = 0;
          let artNrs: string[] = [];

          box.template.cells.forEach((cell) => {
            cell.content.forEach((content) => {
              if (content.artNr.toLowerCase().includes(searchQuery.toLowerCase())) {
                nrResultsPerLocation += 1;
                nrResultsCurrentBox += 1;
                artNrs = [...artNrs, content.artNr];
                cell["containsArtNr"] = true;
              }
            });
          });
          if (nrResultsCurrentBox) {
            boxResults.push({
              box: box,
              id: box.id,
              label: box.label,
              results: nrResultsCurrentBox,
              artNrs: artNrs,
            });
          }
        });
        if (!nrResultsPerLocation) return <></>;

        return ResultItem(key, locationAndBoxes.name, nrResultsPerLocation, boxResults, onSelectResult);
      });

      return results;
    }
  };
  const nrElems = Search(searchQuery);
  if (!nrElems) {
    return (
      <Flex p={32} align="center" justify="center" textAlign="center">
        {searchQuery.length ? "No matches found!" : "Keep typing to search for items"}
      </Flex>
    );
  }

  return (
    <Flex p={4} maxH="80vh" overflowY="scroll" direction="column" borderRadius="md">
      <Box>{Search(searchQuery)}</Box>
    </Flex>
  );
}

export default SearchResults;
