import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@wiretronic/iris";
import "../../styles/content.css";
import { CellContent } from "../BoxEditorAndViewerFunctionality";
import { BoxTemplate } from "../../BackendConnections";

interface Props {
  box: BoxTemplate;
  setArtNrOfInterest(artNr: string): void;
  cellOfInterest: number;
}

function PreviewBoxPerCellContent({ box, setArtNrOfInterest, cellOfInterest }: Props): JSX.Element {
  const contents: CellContent[] = box.template.cells[cellOfInterest - 1].content;

  return (
    <>
      {box.template.cells.forEach((cell, cellIndex) => {
        <Box
          key={`cell-${cellIndex}`}
          style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            rowGap: "2px",
          }}
        />;
      })}
      {contents.length > 0 ? (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th></Th>
              <Th>Art. no</Th>
              <Th>Quantity</Th>
            </Tr>
          </Thead>
          <Tbody>
            {contents.map((content, i) => {
              return (
                <Tr key={i} onClick={() => setArtNrOfInterest(content.artNr)} cursor="pointer">
                  <Td>
                    <img width={"30px"} height={"30px"} src={content.img} alt={content.artNr}></img>
                  </Td>
                  <Td>{content.artNr}</Td>
                  <Td>{content.quantity}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      ) : (
        <Flex w="full" h="full" justify="center" align="center">
          This cell is empty
        </Flex>
      )}
    </>
  );
}
export default PreviewBoxPerCellContent;
