import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Text,
} from "@wiretronic/iris";
import { useCallback, useContext, useEffect, useState } from "react";
import { MdEdit, MdGrid4X4, MdVisibility } from "react-icons/md";
import { NavLink, useParams } from "react-router-dom";
import { UserContext } from "../User";
import "../styles/BoxTemplates.css";
import "../styles/content.css";
import { BoxTemplate, getBoxesFromUGD } from "../BackendConnections";
import { InventoryData, Cell } from "./BoxEditorAndViewerFunctionality";
import RenderCellContentTable from "./CellContentTable";
import Loading from "./Loading";
import PreviewBoxPerCellContent from "./PreviewBoxPage/PreviewBoxPerCellContent";
import PreviewBoxContentSummary from "./PreviewBoxPage/PreviewBoxContentSummary";

function PreviewBox() {
  const [box, setBox] = useState<BoxTemplate>();
  const [cellOfInterest, setCellOfInterest] = useState<number>();
  const [showSummary, setShowSummary] = useState(false);
  const [artNrOfInterest, setArtNrOfInterest] = useState<string | undefined>();
  const { token, logout } = useContext(UserContext);
  const { id } = useParams();
  const filterBoxes = useCallback(
    (inventoryData: InventoryData) => {
      for (const locationAndBoxes of Object.values(inventoryData)) {
        for (let i = 0; i < locationAndBoxes.boxes.length; i++) {
          if (locationAndBoxes.boxes[i].id === id) {
            return locationAndBoxes.boxes[i];
          }
        }
      }
    },
    [id]
  );

  useEffect(() => {
    getBoxesFromUGD(token)
      .then((inventoryData) => {
        setBox(filterBoxes(inventoryData));
      })
      .catch((reason) => {
        console.log(reason);
        logout();
      });
  }, [logout, token, filterBoxes]);

  const checkIfContainsArtNrOfInterest = (cell: Cell): boolean => {
    for (const content of cell.content) {
      if (artNrOfInterest !== undefined && content.artNr.toLowerCase().includes(artNrOfInterest.toLowerCase())) {
        return true;
      }
    }
    return false;
  };

  const _setArtNrOfInterest = (artNr: string) => {
    if (artNr === artNrOfInterest) {
      setArtNrOfInterest(undefined);
    } else {
      setArtNrOfInterest(artNr);
    }
  };

  const PreviewBoxGrid = (box: BoxTemplate) => {
    const styles = (cell: Cell) => {
      return box.template.coordinateBasedLayout
        ? {
            left: `${cell.left! * 100}%`,
            top: `${cell.top! * 100}%`,
            right: `${cell.right! * 100}%`,
            bottom: `${cell.bottom! * 100}%`,
          }
        : {
            gridColumn: `span ${cell.colSpan} / auto`,
            gridRow: `span ${cell.rowSpan} / auto`,
            display: "grid",
            gridTemplateRows: "min-content auto",
          };
    };

    const cells = box.template.cells.map((cell, i) => {
      const isEmphasized = checkIfContainsArtNrOfInterest(cell);
      return box.template.coordinateBasedLayout ? (
        <Box
          onClick={() => {
            setCellOfInterest(i + 1);
            setShowSummary(true);
          }}
          transform={`skew(${cell.skewXAxis!}deg)`}
          style={styles(cell)}
          key={i}
          position="absolute"
          cursor="pointer"
          transition="border-color 120ms, box-shadow 120ms, background-color 120ms"
          bg="gray.300"
          _hover={{
            bg: "gray.500",
          }}
          borderWidth={1}
          borderRadius="sm"
          borderColor="gray.700"
        >
          {cell.content[0] !== undefined && cell.content[0].img !== undefined && (
            <>
              <Flex width="80%" position="absolute" bottom="100%" direction="column" align="center">
                <Image
                  src={cell.content[0].img}
                  alt=""
                  width="100%"
                  height="auto"
                  maxWidth={100}
                  maxHeight={100}
                  fit={"contain"}
                  paddingBottom={4}
                />
                <Box h={8} w="1px" bg="gray.700" />
              </Flex>
            </>
          )}
          <Flex
            transformOrigin="0, 50%"
            transform="rotate(90deg) translate(25%, 50%)"
            width="60px"
            height="30px"
            align="center"
            justify="center"
          >
            {cell.content.length > 0 && (
              <Text textAlign="center" fontSize="2xs">
                {cell.content[0].artNr}
              </Text>
            )}
          </Flex>
        </Box>
      ) : (
        <Box
          onClick={() => {
            setCellOfInterest(i + 1);
            setShowSummary(true);
          }}
          style={styles(cell)}
          key={i}
          borderWidth={2}
          borderRadius="md"
          cursor="pointer"
          transition="border-color 120ms, box-shadow 120ms"
          _hover={{
            borderColor: "indigo.500",
            boxShadow: "outline",
          }}
          borderColor={isEmphasized ? "indigo.500" : "inherit"}
          boxShadow={isEmphasized ? "outline" : "none"}
          background={isEmphasized ? "indigo.50" : "white"}
        >
          <>
            <Text align="center">{i + 1}</Text>
            <TableContainer
              overflowX="unset"
              overflowY="auto"
              sx={{
                "&::-webkit-scrollbar": {
                  width: 2,
                },
                "&::-webkit-scrollbar-track": {
                  width: 2,
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "gray.400",
                  borderRadius: 100,
                },
              }}
            >
              <Table variant="simple" size="sm">
                <Tbody>
                  <RenderCellContentTable cellContent={cell.content} />
                </Tbody>
              </Table>
            </TableContainer>
          </>
        </Box>
      );
    });

    return box.template.coordinateBasedLayout ? (
      <div
        style={{
          position: "relative",
          height: "100%",
          aspectRatio: box.template.aspectRatio,
          margin: "auto",
        }}
      >
        <Image
          opacity={0.3}
          alt="Extractionstool box"
          src={box.template.image}
          style={{ height: "100%", width: "100%", position: "absolute" }}
        />
        {cells}
      </div>
    ) : (
      <div className={box.template.name}>{cells}</div>
    );
  };

  const BoxOverview = (boxResults: BoxTemplate) => {
    const styles = (cell: Cell, i?: number) => {
      return boxResults.template.coordinateBasedLayout
        ? {
            left: `${cell.left! * 100}%`,
            top: `${cell.top! * 100}%`,
            bottom: `${cell.bottom! * 100}%`,
            right: `${cell.right! * 100}%`,
            transform: `skew(${cell.skewXAxis!}deg)`,
          }
        : {
            gridColumn: `span ${cell.colSpan} / auto`,
            gridRow: `span ${cell.rowSpan} / auto`,
          };
    };
    const BoxGrid = (box: BoxTemplate) => {
      const cells: any[] = [];
      box.template.cells.forEach((cell, i) => {
        const bgColor = `gray.${Math.max(1, Math.min(7, cell.content.length + 1)) * 100}`;
        cells.push(
          <Box
            style={styles(cell, i)}
            backgroundColor={cellOfInterest === undefined || i !== cellOfInterest - 1 ? bgColor : "indigo.700"}
            borderRadius="md"
            position={box.template.coordinateBasedLayout ? "absolute" : undefined}
            key={i}
            cursor="pointer"
            onClick={() => setCellOfInterest(i + 1)}
          />
        );
      });
      return !box.template.coordinateBasedLayout ? (
        <Box
          height={`${200 + (box.template.nrRows - 1) * 1.414}px`}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            className={box.template.name}
            style={{ height: 200, aspectRatio: 1.414, boxSizing: "border-box", rowGap: "2px", columnGap: "2px" }}
          >
            {cells}
          </div>
        </Box>
      ) : (
        <Box style={{ aspectRatio: box.template.aspectRatio ?? 696 / 541, position: "relative" }}>
          {cells}
          <img alt="Template" src={boxResults.template.image} />
        </Box>
      );
    };

    return (
      <Flex flexDirection={"column"} w="full" style={{ width: "100%", justifyContent: "flex-start" }}>
        <Box role="group" p={2}>
          <Box>{BoxGrid(boxResults)}</Box>
        </Box>
      </Flex>
    );
  };

  return box ? (
    <>
      <Flex p={6} align="center" w="full" justify="space-between">
        <Box>
          <Heading size="sm" color="gray.600" fontWeight="medium">
            <Flex align="center" gap={2}>
              <MdGrid4X4 />
              {box.template.name}
            </Flex>
          </Heading>
          <Heading mt={2}>{box.label}</Heading>
        </Box>
        <Flex gap={4} align="center">
          <Button
            leftIcon={<MdVisibility />}
            variant="outline"
            onClick={() => {
              setCellOfInterest(undefined);
              setShowSummary(true);
            }}
          >
            View summary
          </Button>
          <Box as={NavLink} to={`/edit/${id}`}>
            <Button leftIcon={<MdEdit />} variant="outline">
              Edit
            </Button>
          </Box>
        </Flex>
      </Flex>
      <Modal
        isOpen={showSummary}
        motionPreset="slideInRight"
        onClose={() => {
          setCellOfInterest(undefined);
          setArtNrOfInterest(undefined);
          setShowSummary(false);
        }}
        size="5xl"
      >
        <ModalOverlay />
        <ModalContent position="fixed" right={8} top={8} bottom={8} my={0}>
          <Box w="40%" p={4} bg="white" borderLeftRadius="md" position="absolute" right="100%" top={48}>
            {BoxOverview(box)}
          </Box>
          <ModalHeader>
            {cellOfInterest !== undefined ? `Contents - Cell ${cellOfInterest}` : "Content - Summary"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY="auto">
            <Box h="full">
              {cellOfInterest !== undefined ? (
                <>
                  <PreviewBoxPerCellContent
                    box={box}
                    setArtNrOfInterest={_setArtNrOfInterest}
                    cellOfInterest={cellOfInterest}
                  />
                </>
              ) : (
                <>
                  <PreviewBoxContentSummary box={box} setArtNrOfInterest={_setArtNrOfInterest} />
                </>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box className="box-grid-wrapper" margin={"auto"} p={6}>
        {PreviewBoxGrid(box)}
      </Box>
    </>
  ) : (
    <Loading />
  );
}

export default PreviewBox;
