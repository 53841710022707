import { authBaseEndpoint, ugdBaseEndpoint } from "./Environment";
import { Template } from "./components/BoxEditorAndViewerFunctionality";
import uuid from "react-uuid";

export interface LocationAndBoxTemplate {
  name: string;
  boxes: BoxTemplate[];
}

export interface BoxTemplate {
  id: string;
  label: string;
  startingDate: string;
  finishDate: string;
  template: Template;
}

export async function validateJWT(token: string | null): Promise<boolean> {
  const headers = token ? { Authorization: "Bearer " + token } : undefined;
  try {
    const res = await fetch(new URL("/api/validatejwt", authBaseEndpoint), {
      headers: headers,
    });
    return res.status === 200;
  } catch (e) {
    return false;
  }
}

export const removeLocationFromUGD = async (token: string | null, locationID: string) => {
  const headers = token ? { "Content-Type": "application/json", Authorization: "Bearer " + token } : undefined;
  const inventoryData = await getBoxesFromUGD(token);
  if (!inventoryData[locationID]) {
    throw new Error("This location does not exist!");
  } else {
    delete inventoryData[locationID];
    return fetch(new URL("company-generated-data", ugdBaseEndpoint), {
      method: "PUT",
      headers: headers,
      body: JSON.stringify({ key: "DIW", value: inventoryData }),
    });
  }
};

export const createNewLocationInUGD = async (token: string | null, data: { name: string }) => {
  const headers = token ? { "Content-Type": "application/json", Authorization: "Bearer " + token } : undefined;
  const id = uuid();
  const savedBoxes = await getBoxesFromUGD(token);
  Object.values(savedBoxes).forEach((locationAndBoxes) => {
    if (locationAndBoxes.name === data.name) throw new Error("A location with this name already exists");
  });
  savedBoxes[id] = { name: data.name, boxes: [] };
  return fetch(new URL("company-generated-data", ugdBaseEndpoint), {
    method: "PUT",
    headers: headers,
    body: JSON.stringify({ key: "DIW", value: savedBoxes }),
  });
};

export const saveBoxesToUGD = async (
  token: string | null,
  data: BoxTemplate[],
  locationKey: string,
  appendToExistingBoxes?: boolean
) => {
  const headers = token ? { "Content-Type": "application/json", Authorization: "Bearer " + token } : undefined;

  try {
    const inventoryData = await getBoxesFromUGD(token);
    if (appendToExistingBoxes && inventoryData[locationKey]) {
      inventoryData[locationKey].boxes = [...inventoryData[locationKey].boxes, ...data];
    } else {
      inventoryData[locationKey].boxes = data;
    }
    return fetch(new URL("company-generated-data", ugdBaseEndpoint), {
      method: "PUT",
      headers: headers,
      body: JSON.stringify({ key: "DIW", value: inventoryData }),
    });
  } catch (error) {
    console.log(error);
  }
};

export const getBoxesFromUGD = async (token: string | null): Promise<Record<string, LocationAndBoxTemplate>> => {
  const headers = token ? { Authorization: "Bearer " + token } : undefined;
  const response = await fetch(new URL("company-generated-data?key=DIW", ugdBaseEndpoint), {
    method: "GET",
    headers: headers,
  });
  if (response.status === 200) {
    const savedBoxes = await response.json();
    return savedBoxes["DIW"];
  } else {
    return {};
  }
};
