import {
  Box,
  ButtonGroup,
  Flex,
  Heading,
  Icon,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Tooltip,
  Image,
} from "@wiretronic/iris";
import { useContext, useEffect, useState } from "react";
import { MdEdit, MdVisibility, MdWork } from "react-icons/md";
import { Link, NavLink } from "react-router-dom";
import { BoxTemplate, getBoxesFromUGD } from "../BackendConnections";
import { UserContext } from "../User";
import "../styles/BoxTemplates.css";
import { defaultExtractionToolsBoxAspectRatio } from "./Location";
import { InventoryData } from "./BoxEditorAndViewerFunctionality";

const getLastCreated = (boxes: BoxTemplate[]) => {
  const ret = [...boxes];
  ret.sort((a, b) => (new Date(a.startingDate) > new Date(b.startingDate) ? -1 : 1));
  return ret.slice(0, 15);
};

const getLastEdited = (boxes: BoxTemplate[]) => {
  const ret = [...boxes];
  ret.sort((a, b) => (new Date(a.finishDate) > new Date(b.finishDate) ? -1 : 1));
  return ret.slice(0, 15);
};

const Start = () => {
  const [inventoryData, setInventoryData] = useState<InventoryData>({});
  const { token, logout } = useContext(UserContext);

  useEffect(() => {
    getBoxesFromUGD(token)
      .then((savedBoxes) => {
        setInventoryData(savedBoxes);
      })
      .catch((reason) => {
        console.log(reason);
        logout();
      });
  }, [logout, token]);

  const allBoxes = Object.values(inventoryData)
    .map((contents) => contents.boxes)
    .flat();

  const lastEdited = getLastEdited(allBoxes);
  const lastCreated = getLastCreated(allBoxes);

  return (
    <Box w="full" pb={32}>
      <Heading borderBottomWidth={1} p={6}>
        Overview
      </Heading>

      <Flex gap={4} mt={8} px={6}>
        <Flex
          as={NavLink}
          to="/add"
          direction="column"
          p={4}
          w={64}
          borderWidth={1}
          alignItems="center"
          gap={2}
          borderRadius="md"
          bg="white"
          _hover={{
            background: "gray.50",
          }}
          transition="background 120ms"
        >
          <Icon as={MdWork} color="indigo.700" fontSize={24} />
          <Text>New box</Text>
        </Flex>
      </Flex>
      <Tabs variant="line" pt={6}>
        <TabList>
          <Tab ml={6}>Recently created</Tab>
          <Tab>Recently edited</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box>
              {lastCreated.length > 0 ? (
                <>
                  <TableContainer>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th></Th>
                          <Th>Label</Th>
                          <Th>Location</Th>
                          <Th>Created at</Th>
                          <Th>Last edited at</Th>
                          <Th></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {lastCreated.map((box, i) => {
                          const cells: JSX.Element[] = [];
                          box.template.cells.forEach((cell, ind) => {
                            const bgColor = `gray.${Math.max(1, Math.min(7, cell.content.length + 1)) * 100}`;

                            box.template.coordinateBasedLayout
                              ? cells.push(
                                  <Box
                                    key={ind}
                                    position="absolute"
                                    left={`${cell.left! * 100}%`}
                                    top={`${cell.top! * 100}%`}
                                    right={`${cell.right! * 100}%`}
                                    bottom={`${cell.bottom! * 100}%`}
                                    backgroundColor={bgColor}
                                    transform={`skew(${cell.skewXAxis}deg)`}
                                  ></Box>
                                )
                              : cells.push(
                                  <Box
                                    key={ind}
                                    gridColumn={`span ${cell.colSpan} / auto`}
                                    gridRow={`span ${cell.rowSpan} / auto`}
                                    backgroundColor={bgColor}
                                    borderRadius="sm"
                                  />
                                );
                          });

                          const locationKey = Object.keys(inventoryData).find((key) =>
                            inventoryData[key].boxes.some((b) => b.id === box.id)
                          );
                          const location = locationKey ? inventoryData[locationKey].name : null;
                          const items = box.template.cells.reduce((p, c) => p + c.content.length, 0);
                          return (
                            <Tr color="gray.600" key={i}>
                              <Td w="125px">
                                {!box.template.coordinateBasedLayout ? (
                                  <Box className="storage-box-grid-wrapper">
                                    <Box className={box.template.name} rowGap="1px" columnGap="1px">
                                      {cells}
                                    </Box>
                                  </Box>
                                ) : (
                                  <Box
                                    w="125px"
                                    className={box.template.name}
                                    position="relative"
                                    style={{
                                      aspectRatio: box.template.aspectRatio ?? defaultExtractionToolsBoxAspectRatio,
                                    }}
                                  >
                                    <Image src={box.template.image} />
                                    {cells}
                                  </Box>
                                )}
                              </Td>
                              <Td>
                                <Box as={Link} to={`/preview/${box.id}`}>
                                  <Text color="chakra-body-text" fontWeight="semibold">
                                    {box.label}
                                  </Text>
                                  <Text fontSize="sm">{items === 1 ? `${items} item` : `${items} items`}</Text>
                                </Box>
                              </Td>
                              <Td>
                                <Link to={`/location/${location}`}>{location}</Link>
                              </Td>
                              <Td>
                                {new Intl.DateTimeFormat("en-SE", {
                                  dateStyle: "long",
                                  timeStyle: "short",
                                }).format(new Date(box.startingDate))}
                              </Td>
                              <Td>
                                {new Intl.DateTimeFormat("en-SE", { dateStyle: "long", timeStyle: "short" }).format(
                                  new Date(box.finishDate)
                                )}
                              </Td>
                              <Td textAlign="right">
                                <ButtonGroup variant="tertiary">
                                  <Tooltip label="Preview">
                                    <IconButton
                                      as={NavLink}
                                      to={`/preview/${box.id}`}
                                      aria-label="Preview"
                                      icon={<MdVisibility />}
                                    />
                                  </Tooltip>
                                  <Tooltip label="Edit">
                                    <IconButton
                                      as={NavLink}
                                      to={`/edit/${box.id}`}
                                      aria-label="Edit"
                                      icon={<MdEdit />}
                                    />
                                  </Tooltip>
                                </ButtonGroup>
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <Text>No boxes</Text>
              )}
            </Box>
          </TabPanel>
          <TabPanel>
            <Box>
              {lastEdited.length > 0 ? (
                <>
                  <TableContainer>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th></Th>
                          <Th>Label</Th>
                          <Th>Location</Th>
                          <Th>Created at</Th>
                          <Th>Last edited at</Th>
                          <Th></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {lastEdited.map((box, i) => {
                          const cells: JSX.Element[] = [];
                          box.template.cells.forEach((cell, ind) => {
                            const bgColor = `gray.${Math.max(1, Math.min(7, cell.content.length + 1)) * 100}`;

                            box.template.coordinateBasedLayout
                              ? cells.push(
                                  <Box
                                    key={ind}
                                    position="absolute"
                                    left={`${cell.left! * 100}%`}
                                    top={`${cell.top! * 100}%`}
                                    right={`${cell.right! * 100}%`}
                                    bottom={`${cell.bottom! * 100}%`}
                                    backgroundColor={bgColor}
                                    transform={`skew(${cell.skewXAxis}deg)`}
                                  ></Box>
                                )
                              : cells.push(
                                  <Box
                                    key={ind}
                                    gridColumn={`span ${cell.colSpan} / auto`}
                                    gridRow={`span ${cell.rowSpan} / auto`}
                                    backgroundColor={bgColor}
                                    borderRadius="sm"
                                  />
                                );
                          });

                          const location = Object.keys(inventoryData).find((key) =>
                            inventoryData[key].boxes.some((b) => b.id === box.id)
                          );
                          const items = box.template.cells.reduce((p, c) => p + c.content.length, 0);

                          return (
                            <Tr color="gray.600" key={i}>
                              <Td w="125px">
                                {!box.template.coordinateBasedLayout ? (
                                  <Box className="storage-box-grid-wrapper">
                                    <Box className={box.template.name} rowGap="1px" columnGap="1px">
                                      {cells}
                                    </Box>
                                  </Box>
                                ) : (
                                  <Box
                                    w="125px"
                                    className={box.template.name}
                                    position="relative"
                                    style={{
                                      aspectRatio: box.template.aspectRatio ?? defaultExtractionToolsBoxAspectRatio,
                                    }}
                                  >
                                    <Image src={box.template.image} />
                                    {cells}
                                  </Box>
                                )}
                              </Td>
                              <Td>
                                <Box as={Link} to={`/preview/${box.id}`}>
                                  <Text color="chakra-body-text" fontWeight="semibold">
                                    {box.label}
                                  </Text>
                                  <Text fontSize="sm">{items === 1 ? `${items} item` : `${items} items`}</Text>
                                </Box>
                              </Td>
                              <Td>
                                <Link to={`/location/${location}`}>{location}</Link>
                              </Td>
                              <Td>
                                {new Intl.DateTimeFormat("en-SE", {
                                  dateStyle: "long",
                                  timeStyle: "short",
                                }).format(new Date(box.startingDate))}
                              </Td>
                              <Td>
                                {new Intl.DateTimeFormat("en-SE", { dateStyle: "long", timeStyle: "short" }).format(
                                  new Date(box.finishDate)
                                )}
                              </Td>
                              <Td textAlign="right">
                                <ButtonGroup variant="tertiary">
                                  <Tooltip label="Preview">
                                    <IconButton
                                      as={NavLink}
                                      to={`/preview/${box.id}`}
                                      aria-label="Preview"
                                      icon={<MdVisibility />}
                                    />
                                  </Tooltip>
                                  <Tooltip label="Edit">
                                    <IconButton
                                      as={NavLink}
                                      to={`/edit/${box.id}`}
                                      aria-label="Edit"
                                      icon={<MdEdit />}
                                    />
                                  </Tooltip>
                                </ButtonGroup>
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <Text>No boxes</Text>
              )}
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Start;
