import { useState } from "react";

export default function useLocalStorageState(key: string): [string | null, (x: string | null) => void] {
  const [value, _setValue] = useState(localStorage.getItem(key));

  const setValue = (newValue: string | null) => {
    _setValue(newValue);
    if (newValue) localStorage.setItem(key, newValue);
    else localStorage.removeItem(key);
  };

  return [value, setValue];
}
