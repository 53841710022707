import { Input, InputGroup, InputRightElement } from "@wiretronic/iris";
import { MdSearch } from "react-icons/md";

type SearchProps = {
  setQueryString: React.Dispatch<React.SetStateAction<string>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

export const SearchBar = (props: SearchProps) => {
  let searchTimer: number;

  const onSearchUpdate = (newQueryString: string) => {
    props.setQueryString(newQueryString);
    window.clearTimeout(searchTimer);
    searchTimer = window.setTimeout(() => {
      props.setPage(1);
    }, 1000);
  };
  return (
    <InputGroup>
      <InputRightElement children={<MdSearch />} />
      <Input type={"search"} onChange={(e) => onSearchUpdate(e.currentTarget.value)} placeholder="Search for product" />
    </InputGroup>
  );
};
