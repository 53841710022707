import { Box, Button, HStack } from "@wiretronic/iris";
import { Dispatch } from "react";
import { SearchBar } from "./SearchBar";
import { Pagination } from "./Pagination";
import { MdArrowBack } from "react-icons/md";

type ListBarProps = {
  setQueryString: Dispatch<React.SetStateAction<string>>;
  setPage: Dispatch<React.SetStateAction<number>>;
  page: number;
  isLoading: boolean;
  shiftPage: (by: number) => void;
  setIsInCellEditMode(val: boolean): void;
};

export const ListBar = (props: ListBarProps) => {
  return (
    <HStack>
      <Box>
        <Button variant="tertiary" leftIcon={<MdArrowBack />} onClick={() => props.setIsInCellEditMode(false)}>
          View contents
        </Button>
      </Box>
      <SearchBar setQueryString={props.setQueryString} setPage={props.setPage} />
      <Pagination page={props.page} shiftPage={props.shiftPage} isLoading={props.isLoading} />
    </HStack>
  );
};
