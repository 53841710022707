import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@wiretronic/iris";
import "../../styles/content.css";

interface Props {
  isOpen: boolean;
  onClose(): void;
  deleteCellContent(): void;
  activeCell: number | undefined;
}

function ConfirmDeleteModal({ isOpen, onClose, deleteCellContent, activeCell }: Props) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure you wish to delete the contents of cell {activeCell! + 1}?</ModalHeader>
          <ModalCloseButton />
          <ModalBody></ModalBody>
          <ModalFooter id="empty-cell-modal-footer">
            <Button
              variant="negative"
              onClick={() => {
                deleteCellContent();
                onClose();
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ConfirmDeleteModal;
