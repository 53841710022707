import { Box, Flex, Image, Table, TableContainer, Tbody, Text } from "@wiretronic/iris";
import "../styles/BoxTemplates.css";
import "../styles/content.css";
import { Cell, Template } from "./BoxEditorAndViewerFunctionality";
import RenderCellContentTable from "./CellContentTable";
interface Props {
  boxName: string;
  activeCell: number | undefined;
  setActiveCell(index: number): void;
  setIsInCellEditMode(val: boolean): void;
  existsInCell: number[];
  template: Template;
}

function GenerateBoxGrid({ boxName, activeCell, setActiveCell, setIsInCellEditMode, existsInCell, template }: Props) {
  const styles = (cell: Cell) => {
    return template.coordinateBasedLayout
      ? {
          left: `${cell.left! * 100}%`,
          top: `${cell.top! * 100}%`,
          bottom: `${cell.bottom! * 100}%`,
          right: `${cell.right! * 100}%`,
        }
      : {
          gridColumn: `span ${cell.colSpan} / auto`,
          gridRow: `span ${cell.rowSpan} / auto`,
          display: "grid",
          gridTemplateRows: "min-content auto",
        };
  };

  const styledCells = template.cells.map((cell, i) => {
    return template.coordinateBasedLayout ? (
      <Box
        onClick={() => {
          setActiveCell(i);
          setIsInCellEditMode(false);
        }}
        transform={`skew(${cell.skewXAxis!}deg)`}
        style={styles(cell)}
        key={i}
        position="absolute"
        cursor="pointer"
        transition="border-color 120ms, box-shadow 120ms, background-color 120ms"
        bg="gray.300"
        _hover={{
          bg: "gray.500",
        }}
        borderWidth={1}
        borderRadius="sm"
        borderColor="gray.700"
      >
        {cell.content[0] !== undefined && cell.content[0].img !== undefined && (
          <>
            <Flex width="80%" position="absolute" bottom="100%" direction="column" align="center">
              <Image
                src={cell.content[0].img}
                alt=""
                width="100%"
                maxWidth={100}
                maxHeight={100}
                fit={"contain"}
                paddingBottom={4}
              />
              <Box h={8} w="1px" bg="gray.700" />
            </Flex>
          </>
        )}
        <Flex
          transformOrigin="0, 50%"
          transform="rotate(90deg) translate(25%, 50%)"
          width="60px"
          height="30px"
          align="center"
          justify="center"
        >
          {cell.content.length > 0 && (
            <Text textAlign="center" fontSize="2xs">
              {cell.content[0].artNr}
            </Text>
          )}
        </Flex>
      </Box>
    ) : (
      <Box
        key={i}
        style={styles(cell)}
        borderWidth={2}
        borderRadius="md"
        cursor="pointer"
        _hover={
          activeCell !== i
            ? {
                background: "gray.100",
              }
            : {}
        }
        transition="all 120ms"
        onClick={() => {
          setActiveCell(i);
          setIsInCellEditMode(false);
        }}
        borderColor={activeCell === i ? "indigo.500" : "inherit"}
        boxShadow={activeCell === i ? "outline" : "none"}
        background={
          template.coordinateBasedLayout
            ? activeCell === i
              ? "indigo.500"
              : cell.content.length > 0
              ? "orange"
              : "initial"
            : activeCell === i
            ? "indigo.50"
            : "initial"
        }
      >
        <Text align="center">{i + 1}</Text>
        <TableContainer
          overflowX="unset"
          overflowY="auto"
          sx={{
            "&::-webkit-scrollbar": {
              width: 2,
            },
            "&::-webkit-scrollbar-track": {
              width: 2,
            },
            "&::-webkit-scrollbar-thumb": {
              background: "gray.400",
              borderRadius: 100,
            },
          }}
        >
          <Table variant="simple">
            <Tbody>
              <RenderCellContentTable cellContent={cell.content} />
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    );
  });
  return template.coordinateBasedLayout ? (
    <div style={{ display: "flex", height: "85vh", justifyContent: "center", alignItems: "center" }}>
      <div style={{ display: "inline-block", position: "relative" }}>
        <Image opacity={0.3} alt="Extractiontools box" src={template.image} style={{ maxHeight: "85vh" }} />
        {styledCells}
      </div>
    </div>
  ) : (
    <Box className="box-grid-wrapper" margin={"auto"} p={6}>
      <div className={boxName}>{styledCells}</div>
    </Box>
  );
}

export default GenerateBoxGrid;
