import { User } from "./interfaces";
import { authBaseEndpoint } from "./Environment";
export async function login(email: string, password: string): Promise<User | null> {
  const response = await fetch(authBaseEndpoint + "/api/login", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      password,
    }),
  });

  if (response.ok) {
    const user = (await response.json()) as User;
    return user;
  } else {
    return null;
  }
}
