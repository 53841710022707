import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  FormControl,
  Grid,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Label,
  Select,
  SlideFade,
  Text,
  useDisclosure,
} from "@wiretronic/iris";
import { useContext, useEffect, useState } from "react";
import { MdSearch, MdVisibility } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import uuid from "react-uuid";
import { getBoxesFromUGD, saveBoxesToUGD } from "../BackendConnections";
import { UserContext } from "../User";
import data from "../templates.json";
import { InventoryData, Template } from "./BoxEditorAndViewerFunctionality";
import LocationCreator from "./LocationCreator";
import ViewProductModal from "./ViewProductModal";

const BoxCreator = () => {
  const { location: preSelectedLocation } = useParams();
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState("");
  const [label, setLabel] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState<Template>();
  const [locations, setLocations] = useState<string[]>([]);
  const [location, setLocation] = useState<string>(() => preSelectedLocation || "");
  const { token } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const { isOpen: isOpenProductModal, onOpen: onOpenProductModal, onClose: onCloseProductModal } = useDisclosure();
  const [inventoryData, setInventoryData] = useState<InventoryData>({});
  const [largerViewInfo, setLargerViewInfo] = useState<{ img: string; artNr: string }>({
    img: "",
    artNr: "",
  });

  const currentDate = new Date();
  const hours = currentDate.getHours() < 10 ? `0${currentDate.getHours()}` : currentDate.getHours();
  const minutes = currentDate.getMinutes() < 10 ? `0${currentDate.getMinutes()}` : currentDate.getMinutes();
  const dayHoursMinutesYear = `${currentDate.toISOString().slice(0, 10)} ${hours}:${minutes}`;

  const filteredTemplates = data["Templates"].filter((x) => x.name.includes(searchString));

  useEffect(() => {
    getBoxesFromUGD(token)
      .then((data) => {
        setInventoryData(data);
        const locationList = [...Object.keys(data)];
        setLocations(locationList);
        if (!preSelectedLocation && locationList.length) {
          setLocation(locationList[0]);
        }
      })
      .catch((reason) => {
        console.log(reason);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [preSelectedLocation, token]);

  const saveCreatedBox = () => {
    if (!label.length || !selectedTemplate) {
      return;
    }

    setLoading(true);

    const id = uuid();

    saveBoxesToUGD(
      token,
      [
        {
          id,
          label,
          template: selectedTemplate,
          startingDate: dayHoursMinutesYear,
          finishDate: dayHoursMinutesYear,
        },
      ],
      location,
      true
    )
      .then(() => {
        navigate(`/edit/${id}`, { replace: true });
      })
      .catch((reason) => {
        console.log(reason);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container maxW={800} h="full" p={6} overflowY="auto">
      <SlideFade in>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            saveCreatedBox();
          }}
        >
          <Flex direction="column" gap={8}>
            <FormControl>
              <Label>Template</Label>
              <InputGroup mt={4}>
                <InputLeftElement>
                  <MdSearch />
                </InputLeftElement>
                <Input placeholder="Search..." onChange={(e) => setSearchString(e.target.value)} isDisabled={loading} />
              </InputGroup>
              <Grid templateColumns="repeat(4, 1fr)" gap={4} mt={4}>
                {filteredTemplates.map((template, idx) => {
                  const isSelected = template.name === selectedTemplate?.name;
                  return (
                    <Box
                      key={idx}
                      borderWidth={1}
                      borderRadius="lg"
                      position="relative"
                      transition="all 120ms"
                      borderColor={isSelected ? "indigo.700" : "inherit"}
                      boxShadow={isSelected ? "outline" : "none"}
                      background={isSelected ? "indigo.50" : "white"}
                      onClick={() => setSelectedTemplate(template)}
                      cursor="pointer"
                    >
                      <IconButton
                        variant="outline"
                        icon={<MdVisibility />}
                        aria-label="Preview"
                        onClick={() => {
                          setLargerViewInfo({
                            artNr: template.name,
                            img: template.image,
                          });
                          onOpenProductModal();
                        }}
                        position="absolute"
                        top={4}
                        right={4}
                      />
                      <Image src={template.image} borderTopRadius="lg" />

                      <Box p={4}>
                        <Text fontSize="lg">{template.name}</Text>
                      </Box>
                    </Box>
                  );
                })}
              </Grid>
            </FormControl>
            <FormControl>
              <Label>Label</Label>
              <Input placeholder="Label" onChange={(e) => setLabel(e.target.value)} isDisabled={loading} />
            </FormControl>
            <FormControl>
              <Flex justify="space-between" align="center">
                <Label>Location</Label>
                <LocationCreator
                  onCreate={() =>
                    getBoxesFromUGD(token)
                      .then((data) => {
                        setInventoryData(data);
                        setLocations([...Object.keys(data)]);
                      })
                      .catch((reason) => {
                        console.log(reason);
                      })
                      .finally(() => {
                        setLoading(false);
                      })
                  }
                />
              </Flex>
              <Select onChange={(e) => setLocation(e.target.value)} defaultValue={location} isDisabled={loading}>
                {locations.map((l, i) => (
                  <option key={i} value={l}>
                    {inventoryData[l].name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <ButtonGroup w="full" justifyContent="flex-end">
                <Button
                  isLoading={loading}
                  type="submit"
                  isDisabled={!label.length || !selectedTemplate || !location.length}
                >
                  Confirm
                </Button>
              </ButtonGroup>
            </FormControl>
          </Flex>
        </form>
      </SlideFade>
      <ViewProductModal isOpen={isOpenProductModal} onClose={onCloseProductModal} largerViewInfo={largerViewInfo} />
    </Container>
  );
};

export default BoxCreator;
