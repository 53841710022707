import { Box, Button, Flex, Grid, Heading, List, Spinner, Text, useToast } from "@wiretronic/iris";
import { useEffect, useState } from "react";
import { ProductDatabaseConnection } from "../../db-client/ProductDataBaseConnection";
import { Node } from "../../interfaces";
import Setting from "../../lib/setting";
import "../../styles/content.css";
import QuantityInput from "../QuantityInput";
import { ListBar } from "./ListBar";
import { ProductListItem } from "./ProductListItem";

interface Props {
  activeCell: number | undefined;
  setCellContent(id: number | undefined, img: string | undefined, artNr: string, quantity: number | undefined): void;
  handleInput(value: number): void;
  setIsInCellEditMode(val: boolean): void;
}

interface State {
  id: number | undefined;
  image: string | undefined;
  artNr: string;
  quantity: number | undefined;
}

function CellEditingPane({ activeCell, setCellContent, handleInput, setIsInCellEditMode }: Props) {
  const [items, setItems] = useState<Node[]>([]);
  const [inValidQty, setInvalidQty] = useState(false);
  const [chosenItem, setChosenItem] = useState<State>({
    id: undefined,
    image: "",
    artNr: "",
    quantity: undefined,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [queryString, setQueryString] = useState("");
  const [artNumberOnly] = useState(true);
  const maxFetchLimit = 50;

  const toast = useToast();

  useEffect(() => {
    if (chosenItem.quantity !== undefined) setInvalidQty(chosenItem.quantity <= 0);
  }, [chosenItem.quantity]);

  useEffect(() => {
    if (chosenItem.quantity !== undefined && chosenItem.quantity > 0) {
      handleInput(chosenItem.quantity);
    }
  }, [chosenItem.quantity, handleInput]);

  useEffect(() => {
    const abortController = new AbortController();
    ProductDatabaseConnection.getSimpleProductList(maxFetchLimit, queryString, page, artNumberOnly, abortController)
      .then((simpleProduct) => {
        setItems(simpleProduct ?? []);
        setIsLoading(false);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          console.warn(reason);
        }
      });

    return () => {
      abortController.abort();
    };
  }, [queryString, page, artNumberOnly]);

  const selectItem = (id: number | undefined, img: string | undefined, artNr: string, quantity: number) => {
    setChosenItem({ id: id, image: img, artNr: artNr, quantity: quantity });
  };

  const cellQuantity = (value: number) => {
    setChosenItem((prevItem) => ({
      ...prevItem,
      quantity: value,
    }));
  };

  const shiftPage = (by: number) => {
    if (page + by < 1 || (items.length < maxFetchLimit - 1 && by > 0)) {
      return;
    }
    const nextPage = Math.max(1, page + by);
    setPage(nextPage);
  };

  const setting = new Setting("api_key");
  const message =
    setting.getStoredValue() === "" ? (
      <p>Please enter a valid wirevision api-key and then reload the product list.</p>
    ) : (
      <p>No products found.</p>
    );

  return (
    <Grid h="full" overflowY="hidden" templateRows="auto min-content">
      <Box overflowY="auto">
        <ListBar
          setIsInCellEditMode={setIsInCellEditMode}
          setQueryString={setQueryString}
          setPage={setPage}
          page={page}
          isLoading={isLoading}
          shiftPage={shiftPage}
        />

        {isLoading ? (
          <Flex h="full" w="full" justify="center" align="center">
            <Spinner />
          </Flex>
        ) : !items.length ? (
          <div className="ui warning message appMessage">{message}</div>
        ) : (
          <List width={"100%"}>
            {items.map((element) => {
              return <ProductListItem selectItem={selectItem} key={element.id} element={element} url={"/product/"} />;
            })}
          </List>
        )}
      </Box>
      <Box borderTopWidth={1} bg="white" pb={4}>
        {chosenItem.artNr !== "" && (
          <>
            <Box p={4}>
              <Box>
                <Flex gap={4} align="center">
                  {chosenItem.image ? (
                    <Box flex={1}>
                      <img src={chosenItem.image} alt={chosenItem.artNr} />
                    </Box>
                  ) : (
                    <></>
                  )}
                  <Box flex={3}>
                    <Heading size="sm" color="gray.600" fontWeight="medium">
                      Article number
                    </Heading>
                    <Heading size="md">{chosenItem.artNr}</Heading>
                    <Box mt={2}>
                      <QuantityInput
                        value={chosenItem.quantity}
                        onIncrement={(old) => {
                          cellQuantity(old + 1);
                        }}
                        onDecrement={(old) => {
                          cellQuantity(old - 1);
                        }}
                        onInputChange={(value) => {
                          cellQuantity(value);
                        }}
                        onSubmitEdit={(value) => {
                          cellQuantity(value);
                        }}
                      />
                      {inValidQty && (
                        <Text color="red.700" mt={2}>
                          Invalid amount
                        </Text>
                      )}
                    </Box>
                  </Box>
                </Flex>
              </Box>
            </Box>

            <Flex justify="stretch" h={16}>
              <Button
                h="full"
                flex={1}
                variant="primary"
                disabled={activeCell === undefined || chosenItem.artNr === "" || inValidQty}
                onClick={() => {
                  setCellContent(chosenItem.id, chosenItem.image, chosenItem.artNr, chosenItem.quantity ?? 1);

                  setChosenItem((prevItem) => ({
                    ...prevItem,
                    image: "",
                    artNr: "",
                    quantity: undefined,
                  }));

                  toast({
                    description: "Item was added to the cell",
                    status: "success",
                    position: "top",
                  });
                }}
              >
                Confirm
              </Button>
            </Flex>
          </>
        )}
      </Box>
    </Grid>
  );
}

export default CellEditingPane;
