import { Box, Content, Page } from "@wiretronic/iris";
import { useContext, useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { validateJWT } from "./BackendConnections";
import { UserContext } from "./User";
import BoxCreator from "./components/BoxCreator";
import ContentPane from "./components/BoxEditorAndViewerFunctionality";
import Loading from "./components/Loading";
import Location from "./components/Location";
import LoginPage from "./components/LoginPage";
import PreviewBox from "./components/PreviewBox";
import SideBar from "./components/SideBar";
import Start from "./components/Start";

const PageContent = (
  <Content overflowY="auto" withPadding={false}>
    <Outlet />
  </Content>
);

const Router = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const { token, logout } = useContext(UserContext);

  useEffect(() => {
    validateJWT(token)
      .then((isTokenValid) => {
        if (!isTokenValid) {
          logout();
        }
      })
      .catch(() => logout())
      .finally(() => setIsLoading(false));
  }, [token, logout]);

  if (isLoading) {
    return (
      <Box w="100vw" h="100vh">
        <Loading />
      </Box>
    );
  }

  if (!token) return <LoginPage />;

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Box bg="gray.100">
            <Box maxW={3840} mx="auto">
              <Page Header={<></>} Sidebar={<SideBar />} Content={PageContent} />
            </Box>
          </Box>
        }
      >
        <Route path="/" element={<Start />} />
        <Route path="/location/:name/" element={<Location />} />
        <Route path="/edit/:id/" element={<ContentPane isInEditMode={true} />} />
        <Route path="/preview/:id/" element={<PreviewBox />} />
        <Route path="/add" element={<BoxCreator />} />
        <Route path="/add/:location" element={<BoxCreator />} />
      </Route>
    </Routes>
  );
};

export default Router;
