import { useContext, useEffect, useState } from "react";
import { UserContext } from "../User";
import React from "react";
import { useDisclosure } from "@wiretronic/iris";
import ConfirmDeleteModal from "./cellEditor/ConfirmDeleteModal";
import ViewProductModal from "./ViewProductModal";
import { getBoxesFromUGD, saveBoxesToUGD } from "../BackendConnections";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "./Loading";
import BoxEditorAndViewer from "./BoxEditorAndViewer";
import { LocationAndBoxTemplate } from "../BackendConnections";
export interface Template {
  name: string;
  image: string;
  nrColumns: number;
  nrRows: number;
  skewFactor?: number;
  aspectRatio?: number;
  coordinateBasedLayout?: boolean;
  cells: Cell[];
}

export interface CellContent {
  id: number | undefined;
  img: string | undefined;
  artNr: string;
  quantity: number | undefined;
}

interface LargerViewInfo {
  //States that are propogated to ViewProductModal
  artNr: string;
  img: string;
}

interface States {
  boxTemplate: string;
  activeCell: number | undefined;
  cellContent: Array<Array<CellContent>>;
  quantity: number | undefined;
  label: string;
  template: Template;
  date: string;
  location: string;
}

export interface Cell {
  left?: number;
  top?: number;
  right?: number;
  bottom?: number;
  colSpan: number;
  rowSpan: number;
  startRow: number;
  startCol: number;
  content: CellContent[];
  containsArtNr?: boolean;
  skewXAxis?: number;
}

interface Props {
  isInEditMode?: boolean;
  showAdd?: boolean;
}

export interface InventoryData {
  [key: string]: LocationAndBoxTemplate;
}

const BoxEditorAndViewerFunctionality = ({ isInEditMode, showAdd }: Props): JSX.Element => {
  const { id, location } = useParams();

  const navigate = useNavigate();
  const [state, setState] = useState<States>(() => ({
    boxTemplate: "",
    activeCell: undefined,
    cellContent: [],
    quantity: undefined,
    label: "default",
    template: { name: "", image: "", nrColumns: 0, nrRows: 0, cells: [] },
    date: "",
    location: location ?? "",
  }));
  const [existsInCell, setExistsInCell] = useState(new Array(state.cellContent.length).fill(0));
  const [largerViewInfo, setLargerViewInfo] = useState<LargerViewInfo>({
    img: "",
    artNr: "",
  });
  const [itemSearch, setItemSearch] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenProductModal, onOpen: onOpenProductModal, onClose: onCloseProductModal } = useDisclosure();

  const { token, logout } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(true);
  const [isSyncingEdit, setIsSyncingEdit] = useState(false);

  const [showAddDiv, setShowAddDiv] = useState(showAdd ?? false);
  const [locationName, setLocationName] = useState("");

  useEffect(() => {
    if (!isInEditMode) {
      setState((prevState) => ({
        ...prevState,
        template: { name: "", image: "", nrColumns: 0, nrRows: 0, cells: [] },
        location: location ?? "",
        boxTemplate: "",
      }));
    }
  }, [isInEditMode, location]);

  useEffect(() => {
    //If user wants to edit a box, find the box in question and set the state.
    if (isInEditMode) {
      getBoxesFromUGD(token)
        .then((UGDData) => {
          Object.entries(UGDData).forEach(([key, locationAndBoxes]) => {
            locationAndBoxes.boxes.forEach((box) => {
              if (box.id === id) {
                setState((prevState) => ({
                  ...prevState,
                  template: box.template,
                  location: key,
                  boxTemplate: box.template.name,
                  label: box.label,
                }));
                setLocationName(locationAndBoxes.name);
                return;
              }
            });
          });
        })
        .catch((error) => {
          console.log(error);
          logout();
        })
        .finally(() => setIsLoading(false));
    }
  }, [isInEditMode, id, logout, token]);

  const syncEdit = async () => {
    const currentDate = new Date();
    const hours = currentDate.getHours() < 10 ? `0${currentDate.getHours()}` : currentDate.getHours();
    const minutes = currentDate.getMinutes() < 10 ? `0${currentDate.getMinutes()}` : currentDate.getMinutes();
    const dayHoursMinutesYear = `${currentDate.toISOString().slice(0, 10)} ${hours}:${minutes}`;
    if (isInEditMode) {
      //After an edit is finished, overwrite the box contents at the index of the box.
      setIsSyncingEdit(true);
      getBoxesFromUGD(token)
        .then(async (savedBoxes) => {
          const iterBoxes: InventoryData = savedBoxes;
          for (const locationAndBoxes of Object.values(iterBoxes)) {
            for (let i = 0; i < locationAndBoxes.boxes.length; i++) {
              if (locationAndBoxes.boxes[i].id === id) {
                let boxesCopy = locationAndBoxes.boxes;
                boxesCopy[i] = {
                  id: id,
                  label: state.label,
                  startingDate: locationAndBoxes.boxes[i].startingDate,
                  finishDate: dayHoursMinutesYear,
                  template: state.template,
                };
                await saveBoxesToUGD(token, boxesCopy, state.location);
                return;
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
          logout();
        })
        .finally(() => {
          setIsSyncingEdit(false);
          navigate(`/location/${state.location}`);
        });
    }
  };

  const setLabel = (label: string) => {
    setState((prevState) => ({
      ...prevState,
      label: label,
    }));
  };

  const handleInput = (value: number) => {
    setState((prevState) => ({
      ...prevState,
      quantity: value,
    }));
  };

  const _setLargerViewInfo = (img: string, artNr: string, e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setLargerViewInfo({ img: img, artNr: artNr });
    e?.stopPropagation();
  };

  const setBoxName = (boxName: string, numCells: number) => {
    setState((prevState) => ({
      ...prevState,
      boxTemplate: boxName,
      numCells: numCells,
    }));
  };

  const setDate = (date?: string) => {
    setState((prevState) => ({
      ...prevState,
      date: date ?? "",
    }));
  };

  const handleItemSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setItemSearch(value);
  };

  const setTemplate = (template: Template) => {
    setState((prevState) => ({
      ...prevState,
      template: template,
    }));
  };

  const setLocation = (location: string) => {
    setState((prevState) => ({
      ...prevState,
      location: location,
    }));
  };

  const checkContains = () => {
    //This function is called when pressing the search button in the right sidebar when viewing a cell.
    //It sets the state existsInCell, which subsequently is used to render a checkmark for each cell in a
    //box that contains the searchquery.
    const existsArrayCopy = [...existsInCell];
    let numMatches = 0;
    state.template.cells.forEach((cell, index) => {
      if (cell) {
        cell.content.map((elem) => {
          if (itemSearch === elem.artNr) {
            existsArrayCopy[index] = 1;
            numMatches += 1;
          }
          return elem;
        });
      }
    });
    if (!numMatches) {
      return;
    }
    setExistsInCell(existsArrayCopy);
  };

  const setActiveCell = (index: number | undefined) => {
    setState((prevState) => ({
      ...prevState,
      activeCell: index === prevState.activeCell ? undefined : index,
    }));
  };

  const changeQuantity = (index: number, updateFn: (old: number) => number) => {
    const cellsCopy = [...state.template.cells];
    const cellContentCopy = [...cellsCopy[state.activeCell!].content];
    cellContentCopy[index].quantity! = updateFn(cellContentCopy[index].quantity!);
    cellsCopy[state.activeCell!].content = cellContentCopy;
    setState((prevState) => ({
      ...prevState,
      template: {
        ...prevState.template,
        cells: cellsCopy,
      },
    }));
  };

  const deleteCellContent = () => {
    const cellContentArrayCopy = [...state.template.cells];
    cellContentArrayCopy[state.activeCell!].content = [];
    setState((prevState) => ({
      ...prevState,
      template: {
        ...prevState.template,
        cells: cellContentArrayCopy,
      },
    }));
  };

  const deleteCellContentEntry = (ind: number | undefined) => {
    const cellsCopy = [...state.template.cells];
    const cellContentCopy = [...cellsCopy[state.activeCell!].content];
    cellContentCopy.splice(ind!, 1);
    cellsCopy[state.activeCell!].content = cellContentCopy;
    setState((prevState) => ({
      ...prevState,
      template: {
        ...prevState.template,
        cells: cellsCopy,
      },
    }));
  };

  const setCellContent = (
    id: number | undefined,
    img: string | undefined,
    artNr: string,
    quantity: number | undefined
  ) => {
    const cellContentArrayCopy = [...state.template.cells];
    const cellContentCopy = cellContentArrayCopy[state.activeCell!].content;
    const image = img ?? "frame_69.png"; //Just to have something, will be replaced with an icon saying "No image available" or something
    let exists = false;
    cellContentCopy.forEach((elem, index) => {
      if (elem.artNr === artNr && elem.quantity !== undefined) {
        elem.quantity += quantity ?? 1;
        exists = true;
      }
    });
    if (!exists) {
      cellContentCopy.push({
        id: id,
        img: image,
        artNr: artNr,
        quantity: quantity,
      });
    }
    cellContentArrayCopy[state.activeCell!].content = cellContentCopy;
    setState((prevState) => ({
      ...prevState,
      template: {
        ...prevState.template,
        cells: cellContentArrayCopy,
      },
    }));
  };

  if ((isLoading || isSyncingEdit) && isInEditMode) return <Loading />;

  return (
    <>
      <ConfirmDeleteModal
        isOpen={isOpen}
        onClose={onClose}
        deleteCellContent={deleteCellContent}
        activeCell={state.activeCell}
      />

      <ViewProductModal isOpen={isOpenProductModal} onClose={onCloseProductModal} largerViewInfo={largerViewInfo} />
      <BoxEditorAndViewer
        showAddDiv={showAddDiv}
        activeCell={state.activeCell}
        setActiveCell={setActiveCell}
        setCellContent={setCellContent}
        deleteCellContentEntry={deleteCellContentEntry}
        changeQuantity={changeQuantity}
        handleInput={handleInput}
        itemSearch={itemSearch}
        handleItemSearch={handleItemSearch}
        existsInCell={existsInCell}
        checkContains={checkContains}
        onOpen={onOpen}
        _setLargerViewInfo={_setLargerViewInfo}
        onOpenProductModal={onOpenProductModal}
        boxTemplate={state.boxTemplate}
        setBoxName={setBoxName}
        setTemplate={setTemplate}
        template={state.template}
        setShowAddDiv={(e: boolean) => setShowAddDiv(e ?? !showAddDiv)} //In some places I set it to false and in others I flip the state.
        setDate={setDate}
        date={state.date}
        setLocation={setLocation}
        location={state.location}
        isInEditMode={isInEditMode}
        syncEdit={syncEdit}
        setLabel={setLabel}
        label={state.label}
        locationName={locationName}
      />
    </>
  );
};

export default BoxEditorAndViewerFunctionality;
