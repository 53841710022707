import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@wiretronic/iris";
import { FormEventHandler, MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { MdAdd } from "react-icons/md";
import { createNewLocationInUGD } from "../BackendConnections";
import { UserContext } from "../User";

interface Props {
  onCreate: () => void;
}

const LocationCreator = ({ onCreate }: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const inputRef = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;
  const { token } = useContext(UserContext);

  useEffect(() => {
    setName("");
  }, [isOpen]);

  const onSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    createNewLocationInUGD(token, { name })
      .then(() => {
        onCreate();
        onClose();
      })
      .catch((error) => {
        console.log(error.message);
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isError = error.length !== 0;

  return (
    <>
      <Button variant="ghost" p={0} h="min-content" leftIcon={<MdAdd />} color="gray.600" onClick={onOpen}>
        Add
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={inputRef}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Create location
            <ModalCloseButton />
          </ModalHeader>
          <form onSubmit={onSubmit}>
            <ModalBody>
              <FormControl isInvalid={isError}>
                <Label htmlFor="name">Location name</Label>
                <Input
                  ref={inputRef}
                  autoComplete="off"
                  spellCheck="false"
                  id="name"
                  type="text"
                  defaultValue={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Location name"
                  minLength={1}
                  required
                />
                <FormErrorMessage>{error}</FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" isDisabled={name.length === 0} isLoading={loading}>
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LocationCreator;
