import { Box, ButtonGroup, Flex, IconButton, Image, Text } from "@wiretronic/iris";
import { MdDelete, MdImageNotSupported, MdVisibility } from "react-icons/md";
import "../../styles/content.css";
import { CellContent } from "../BoxEditorAndViewerFunctionality";
import QuantityInput from "../QuantityInput";

interface Props {
  elem: CellContent;
  deleteCellContentEntry(ind: number | undefined): void;
  index: number;
  changeQuantity(index: number, updateFn: (old: number) => number): void;
  _setLargerViewInfo(img: string, artNr: string): void;
  onOpenProductModal(): void;
}

function CellContentListitem({
  elem,
  deleteCellContentEntry,
  index,
  changeQuantity,
  _setLargerViewInfo,
  onOpenProductModal,
}: Props) {
  return (
    <Flex align="center" justify="space-between" w="full" py={4} borderBottomWidth={1}>
      <Flex flex={3} align="center" gap={4}>
        {elem.img && elem.img.length ? (
          <Image height={16} width={16} objectFit="contain" src={elem.img} alt="N/A" />
        ) : (
          <MdImageNotSupported />
        )}

        <Box>
          <Text>{elem.artNr}</Text>
          <QuantityInput
            value={elem.quantity}
            onIncrement={() => changeQuantity(index, (prev) => prev + 1)}
            onSubmitEdit={(value) => {
              if (value === 0) {
                deleteCellContentEntry(index);
              }
            }}
            onInputChange={(value) => {
              changeQuantity(index, () => Number(value));
            }}
            onDecrement={() => {
              if (elem.quantity === 1) {
                deleteCellContentEntry(index);
              } else {
                changeQuantity(index, (old) => old - 1);
              }
            }}
          />
        </Box>
      </Flex>

      <ButtonGroup variant="tertiary">
        <IconButton
          aria-label="Show"
          icon={<MdVisibility />}
          onClick={() => {
            _setLargerViewInfo(elem.img!, elem.artNr);
            onOpenProductModal();
          }}
        />
        <IconButton aria-label="Delete" icon={<MdDelete />} onClick={(e) => deleteCellContentEntry(index)} />
      </ButtonGroup>
    </Flex>
  );
}

export default CellContentListitem;
