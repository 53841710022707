import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@wiretronic/iris";
import "../../styles/content.css";
import { BoxTemplate } from "../../BackendConnections";
import { CellContent } from "../BoxEditorAndViewerFunctionality";

interface Props {
  box: BoxTemplate;
  setArtNrOfInterest(artNr: string): void;
}

function PreviewBoxContentSummary({ box, setArtNrOfInterest }: Props) {
  const artNrAndQuantity: { [artNr: string]: CellContent } = {};
  box.template.cells.forEach((cell) => {
    cell.content.forEach((content) => {
      artNrAndQuantity[`${content.artNr}`]
        ? (artNrAndQuantity[`${content.artNr}`].quantity! += content.quantity!)
        : (artNrAndQuantity[`${content.artNr}`] = {
            id: content.id,
            artNr: content.artNr,
            img: content.img,
            quantity: content.quantity,
          });
    });
  });

  const perCellContent = (): JSX.Element => {
    if (Object.keys(artNrAndQuantity).length === 0) {
      return (
        <Box height={"100%"}>
          <p style={{ fontSize: 16 }}>This box is empty!</p>
        </Box>
      );
    }
    return (
      <Box
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          rowGap: "2px",
        }}
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th></Th>
              <Th>Art. no</Th>
              <Th>Quantity</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Object.entries(artNrAndQuantity).map(([artNr, content], i) => {
              return (
                <Tr key={i} onClick={() => setArtNrOfInterest(content.artNr)} cursor="pointer">
                  <Td>
                    <img width={"30px"} height={"30px"} src={content.img} alt={content.artNr}></img>
                  </Td>
                  <Td>{content.artNr}</Td>
                  <Td>{content.quantity}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    );
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr",
        rowGap: "10px",
      }}
    >
      {perCellContent()}
    </div>
  );
}

export default PreviewBoxContentSummary;
