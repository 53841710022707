import { Flex, IconButton, Input } from "@wiretronic/iris";
import React from "react";
import { MdAdd, MdRemove } from "react-icons/md";

interface Props {
  value?: number;
  onInputChange: (value: number) => void;
  onIncrement: (old: number) => void;
  onDecrement: (old: number) => void;
  onSubmitEdit: (value: number) => void;
}

const QuantityInput = ({ onInputChange: onChange, onIncrement, onDecrement, value, onSubmitEdit }: Props) => {
  return (
    <Flex mt={2}>
      <IconButton
        aria-label="Incremenet"
        icon={<MdRemove />}
        borderRightRadius={0}
        variant="outline"
        boxShadow="none"
        onClick={() => onDecrement(value === undefined ? 1 : value)}
      />

      <Input
        sx={{
          "&::-webkit-inner-spin-button, &input::-webkit-outer-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },
        }}
        width={16}
        boxShadow="none"
        type="number"
        textAlign="center"
        px="1px"
        mx="-1px"
        borderRadius={0}
        onChange={(e) => {
          onChange(Number(e.target.value));
        }}
        step="1"
        min="0"
        onKeyDown={(e) => {
          if (e.key === "Enter" && value !== undefined) {
            onSubmitEdit(value);
            e.currentTarget.blur();
          }
        }}
        value={value || ""}
      />
      <IconButton
        borderLeftRadius={0}
        aria-label="Increment"
        icon={<MdAdd />}
        variant="outline"
        boxShadow="none"
        onClick={(e) => {
          onIncrement(value === undefined ? 0 : value);
        }}
      />
    </Flex>
  );
};

export default QuantityInput;
