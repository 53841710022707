import {
  Box,
  Button,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  SlideFade,
} from "@wiretronic/iris";
import { useEffect, useState } from "react";
import { MdSearch } from "react-icons/md";
import "../styles/content.css";
import data from "../templates.json";
import { Template } from "./BoxEditorAndViewerFunctionality";
import BoxTemplateItemSelector from "./BoxTemplateSelectorItem";

interface Props {
  setBoxName(boxName: string, numCells: number): void;
  setTemplate(template: Template): void;
  _setLargerViewInfo(img: string, artNr: string): void;
  onOpenProductModal(): void;
  setShowAddDiv(val?: boolean): void;
  setDate(date?: string): void;
  setLocation(location: string): void;
  location: string;
  setLabel(label: string): void;
  label: string;
}

function BoxTemplateSelector({
  setBoxName,
  setTemplate,
  _setLargerViewInfo,
  onOpenProductModal,
  setShowAddDiv,
  setDate,
  setLocation,
  location,
  setLabel,
  label,
}: Props) {
  const currentDate = new Date();
  const hours = currentDate.getHours() < 10 ? `0${currentDate.getHours()}` : currentDate.getHours();
  const minutes = currentDate.getMinutes() < 10 ? `0${currentDate.getMinutes()}` : currentDate.getMinutes();
  const dayHoursMinutesYear = `${currentDate.toISOString().slice(0, 10)} ${hours}:${minutes}`;

  const [template, _setTemplate] = useState<Template>({
    name: "",
    image: "",
    nrColumns: 0,
    nrRows: 0,
    cells: [],
  });
  const [filteredTemplates, setFilteredTemplates] = useState<Template[]>(data["Templates"]);
  const [confirm, setConfirm] = useState(false);
  const [activeInd, setActiveInd] = useState<number | undefined>(undefined);

  const clearAllContent = () => {
    const filteredCopy = [...filteredTemplates];
    filteredCopy.forEach((template) => {
      template.cells.forEach((cell) => {
        cell.content = [];
      });
    });
    setFilteredTemplates(filteredCopy);
  };

  useEffect(() => {
    setConfirm(() => {
      return template.name !== "" && location !== "" && label !== "";
    });
  }, [template, location, label]);

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    setLocation(value);
  }

  function handleLabel(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setLabel(value);
  }

  const searchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (activeInd !== undefined) setActiveInd(undefined);
    if (template.name !== "") _setTemplate({ name: "", image: "", nrColumns: 0, nrRows: 0, cells: [] }); //No need to modify state on every onChange event.
    const filtered: Template[] = data["Templates"].filter((template) => {
      return template.name.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredTemplates(filtered);
  };

  const _setActiveInd = (ind: number | undefined) => {
    if (ind === activeInd) {
      setActiveInd(undefined);
    } else {
      setActiveInd(ind);
    }
  };

  const RenderTemplateItems = () => {
    const templateList = filteredTemplates.map((template, index) => {
      return (
        <BoxTemplateItemSelector
          key={index}
          setBoxName={setBoxName}
          setTemplate={_setTemplate}
          template={template}
          filteredTemplates={filteredTemplates}
          index={index}
          _setLargerViewInfo={_setLargerViewInfo}
          onOpenProductModal={onOpenProductModal}
          activeInd={activeInd}
          setActiveInd={_setActiveInd}
        />
      );
    });
    return templateList;
  };

  return (
    <Box>
      <Heading size="md">Box:</Heading>
      <InputGroup flex={1} size="lg">
        <Input placeholder="Search..." onChange={(e) => searchHandler(e)} type="text" />
        <InputRightElement children={<MdSearch fontSize={"1.8rem"} />} />
      </InputGroup>
      {RenderTemplateItems()}
      <Heading size="md" my={2}>
        Label:
      </Heading>
      <InputGroup flex={1} mb={"10px"} size="lg">
        <Input type="text" onChange={(e) => handleLabel(e)} value={label} />
        <InputRightElement children={<MdSearch fontSize={"1.8rem"} />} />
      </InputGroup>
      <Heading as="h2" size="md" mt={4}>
        Storage location
      </Heading>
      <Select size="lg" placeholder="Select area" value={location} onChange={(e) => handleChange(e)}>
        <option value="Gothenburg">Göteborg</option>
        <option value="Vanersborg">Vänersborg</option>
        <option value="Stockholm">Stockholm</option>
        <option value="Arvika">Arvika</option>
        <option value="Boden">Boden</option>
        <option value="Oslo">Oslo</option>
      </Select>
      <SlideFade in={confirm} unmountOnExit={true}>
        <HStack>
          <p style={{ fontSize: "14px" }}>
            {`Create box of template ${template.name} and store at location
              ${location}?`}
          </p>
          <Button
            disabled={template.name === "" || location === ""}
            onClick={() => {
              setTemplate(template);
              setBoxName(template.name, 10);
              setShowAddDiv(false);
              setDate(dayHoursMinutesYear);
              clearAllContent();
            }}
          >
            Confirm
          </Button>
        </HStack>
      </SlideFade>
    </Box>
  );
}

export default BoxTemplateSelector;
