import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  HStack,
  Text,
  SlideFade,
  useToast,
} from "@wiretronic/iris";

import { MdSettings, MdDelete, MdCancel } from "react-icons/md";
import { useState, useContext } from "react";
import { removeLocationFromUGD } from "../BackendConnections";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../User";

interface Props {
  locationName: string;
}

const LocationSettings = ({ locationName }: Props) => {
  const { name: id } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const { token, logout } = useContext(UserContext);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const navigate = useNavigate();
  const confirmDeleteText = "Confirm delete";
  const toast = useToast();

  const deleteLocation = () => {
    if (!id) return;
    if (confirmDelete) {
      setIsLoading(true);
      removeLocationFromUGD(token, id)
        .then(() => {
          onClose();
          toast({
            title: "Location deleted",
            description: `${locationName} was successfully deleted`,
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        })
        .catch((e) => {
          console.log(e);
          logout();
        })
        .finally(() => {
          setIsLoading(false);
          navigate("/");
        });
      setConfirmDelete(false);
    }
    setConfirmDelete(true);
  };

  return (
    <>
      <Button mr={5} variant="ghost" p={0} h="min-content" leftIcon={<MdSettings />} color="gray.600" onClick={onOpen}>
        Settings
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setConfirmDelete(false);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Location settings - {locationName}
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody></ModalBody>
          <ModalFooter>
            <SlideFade in={confirmDelete} unmountOnExit={true}>
              <HStack mr={2}>
                <Text>{confirmDeleteText}</Text>
                <Button variant={"outline"} leftIcon={<MdCancel />} onClick={() => setConfirmDelete(false)}>
                  Cancel
                </Button>
              </HStack>
            </SlideFade>
            <Button onClick={deleteLocation} leftIcon={<MdDelete />} isLoading={isLoading} variant="negative">
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LocationSettings;
