import {
  Avatar,
  Box,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
  HStack,
} from "@wiretronic/iris";
import { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../User";
import { MdHome, MdLogout, MdSearch, MdViewModule } from "react-icons/md";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { getBoxesFromUGD } from "../BackendConnections";
import "../styles/SideBar.css";
import LocationCreator from "./LocationCreator";
import SearchResults from "./SearchResults";
import { InventoryData } from "./BoxEditorAndViewerFunctionality";

const SideBar = (): JSX.Element => {
  const { pathname } = useLocation();
  const [inventoryData, setInventoryData] = useState<InventoryData>();
  const [searchQuery, setSearchQuery] = useState("");
  const searchRef = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;
  const initialRef = useRef(null);
  const { name } = useParams();
  const { logout, userName, token } = useContext(UserContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [locationsAreFetching, setLocationsAreFetching] = useState(true);
  useEffect(() => {
    getBoxesFromUGD(token)
      .then((savedBoxes) => {
        setInventoryData(savedBoxes);
      })
      .catch((reason) => {
        console.log(reason);
        logout();
      })
      .finally(() => setLocationsAreFetching(false));
  }, [logout, token, name]);

  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  const GenerateLinks = () => {
    if (inventoryData) {
      const results = Object.entries(inventoryData).map(([key, boxes], i) => {
        return (
          <HStack w={"100%"}>
            <Link
              key={i}
              className="location"
              bg={name === key ? "gray.300" : "transparent"}
              color={name === key ? "indigo.800" : "inherit"}
              as={NavLink}
              to={`/location/${key}`}
              px={4}
              py={2}
              w="full"
              borderRadius="base"
              display="flex"
              gap={4}
              alignItems="center"
              _hover={{
                bg: "gray.300",
                color: "indigo.800",
              }}
              _focus={{
                boxShadow: "none",
              }}
              pointerEvents={locationsAreFetching ? "none" : "all"}
            >
              <MdViewModule />
              {boxes.name}
            </Link>
          </HStack>
        );
      });

      return <>{results}</>;
    } else return <></>;
  };

  return (
    <Box bg="gray.100" borderRightWidth={1} h="100vh" minW={300}>
      <Flex direction="column" alignItems="center" h="full">
        <Flex width={"100%"} alignItems="flex-start" mb={4} p={4}>
          <Link className="image-link" _focus={{ color: "white" }} as={NavLink} to={`/`} w="full">
            <Image src={"/images/raaco.svg"} alt="Raaco" width="128px" />
          </Link>
        </Flex>
        <Box w="full" px={6}>
          <Flex
            bgColor="white"
            cursor="pointer"
            onClick={onOpen}
            w="full"
            borderRadius="lg"
            px={2}
            h={8}
            gap={2}
            align="center"
            boxShadow="sm"
            color="gray.600"
            borderWidth={1}
          >
            <Icon as={MdSearch} />
            <Text>Search for Product</Text>
          </Flex>

          <Modal
            isOpen={isOpen}
            initialFocusRef={initialRef}
            onClose={() => {
              setSearchQuery("");
              onClose();
            }}
          >
            <ModalOverlay />
            <ModalContent minW="30%">
              <ModalBody overflow="hidden" p={0}>
                <Flex p={4} borderBottomWidth={1}>
                  <Icon boxSize={8} as={MdSearch} color="gray.600" />
                  <Input
                    bg="white"
                    variant="ghost"
                    placeholder="Search for product..."
                    type="text"
                    boxShadow="none"
                    borderBottom={0}
                    ref={searchRef}
                    fontSize={16}
                    value={searchQuery}
                    onChange={(e) => searchHandler(e)}
                  />
                </Flex>

                <SearchResults
                  searchQuery={searchQuery}
                  onSelectResult={() => {
                    setSearchQuery("");
                    onClose();
                  }}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>

        <Flex direction="column" width={"100%"} alignItems="flex-start">
          <VStack spacing={2} align="flex-start" my={8} px={4} style={{ width: "100%" }}>
            <Heading as="h5" size="sm" fontWeight="medium" color="gray.600">
              Home
            </Heading>
            <Link
              bg={pathname === "/" ? "gray.300" : "transparent"}
              color={pathname === "/" ? "indigo.800" : "inherit"}
              as={NavLink}
              to="/"
              px={4}
              py={2}
              w="full"
              borderRadius="base"
              display="flex"
              gap={4}
              alignItems="center"
              _hover={{
                bg: "gray.300",
                color: "indigo.800",
              }}
              _focus={{
                boxShadow: "none",
              }}
            >
              <MdHome />
              Overview
            </Link>

            <Flex justify="space-between" align="center" w="full" pt={4}>
              <Heading as="h5" size="sm" fontWeight="medium" color="gray.600">
                Locations
              </Heading>
              <LocationCreator
                onCreate={() => {
                  getBoxesFromUGD(token)
                    .then((savedBoxes) => {
                      setInventoryData(savedBoxes);
                    })
                    .catch((reason) => {
                      console.log(reason);
                      logout();
                    });
                }}
              />
            </Flex>

            <GenerateLinks />
          </VStack>
        </Flex>
        <Box mt="auto">
          <Link
            className="location"
            as={NavLink}
            to={`https://wirevision.com`}
            p={4}
            w="full"
            display="flex"
            gap={2}
            color="gray.600"
            alignItems="flex-start"
            flexDirection="column"
            justifyContent="flex-start"
            fontSize="sm"
          >
            Powered by
            <Image src={"/images/wirevision_parallel.svg"} alt="Wirevision" width="30%" />
          </Link>
          <Flex p={4} align="center" gap={4} borderTopWidth={1} w="100%" style={{ zIndex: 200 }}>
            <Avatar name={userName ?? ""} />
            <Text>{userName ?? "Unnamed user"}</Text>
            <Tooltip hasArrow label="Log out" openDelay={500} bg="gray.300" color="black">
              <IconButton
                ml="auto"
                onClick={logout}
                variant="ghost"
                px={0}
                aria-label="Sign out"
                icon={<Icon as={MdLogout} boxSize={6} />}
              />
            </Tooltip>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default SideBar;
