import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  Image,
  HStack,
} from "@wiretronic/iris";
import { useContext, useEffect, useRef, useState } from "react";
import { MdAdd, MdDelete, MdEdit, MdMyLocation, MdVisibility } from "react-icons/md";
import { Link as RouterLink, useParams } from "react-router-dom";
import { BoxTemplate, getBoxesFromUGD, saveBoxesToUGD } from "../BackendConnections";
import { UserContext } from "../User";
import Loading from "./Loading";
import LocationSettings from "./LocationSettings";

export const defaultExtractionToolsBoxAspectRatio = 866 / 1363;

function Location() {
  const { name } = useParams();
  const [boxes, setBoxes] = useState<BoxTemplate[]>();
  const { token, logout } = useContext(UserContext);
  const [locationName, setLocationName] = useState("");
  const [pendingBoxDeletionIndex, setPendingBoxDeletionIndex] = useState<number | undefined>();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const modalRef = useRef(null);
  const deleteBox = async (id: string) => {
    const filteredBoxes = boxes?.filter((box) => {
      return box.id !== id;
    });
    return saveBoxesToUGD(token, filteredBoxes!, name!)
      .then(() => {
        setBoxes(filteredBoxes);
      })
      .catch((reason: string) => {
        console.log(reason);
        logout();
      });
  };

  useEffect(() => {
    getBoxesFromUGD(token)
      .then((savedBoxes) => {
        setBoxes(savedBoxes[name!].boxes);
        setLocationName(savedBoxes[name!].name);
      })
      .catch((reason) => {
        console.log(reason);
        logout();
      });
  }, [name, logout, token]);

  return (
    <Box>
      <Flex w="full" align="center" justify="space-between" p={6}>
        <Box>
          <Heading size="sm" color="gray.600" fontWeight="medium">
            <Flex align="center" gap={2}>
              <MdMyLocation />
              {locationName}
            </Flex>
          </Heading>
          <Heading mt={2}>Boxes</Heading>
        </Box>

        <Flex align="center" gap={4}>
          <HStack>
            <LocationSettings locationName={locationName} />
            <Box as={RouterLink} to={`/add/${name}`} textDecoration="none">
              <Button leftIcon={<MdAdd />}>Add new box</Button>
            </Box>
          </HStack>
        </Flex>
      </Flex>

      {boxes ? (
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th></Th>
                <Th>Label</Th>
                <Th>Location</Th>
                <Th>Created at</Th>
                <Th>Last edited at</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {boxes.map((box, i) => {
                const cells: JSX.Element[] = [];
                box.template.cells.forEach((cell, ind) => {
                  const bgColor = `gray.${Math.max(1, Math.min(7, cell.content.length + 1)) * 100}`;
                  box.template.coordinateBasedLayout
                    ? cells.push(
                        <Box
                          key={ind}
                          position="absolute"
                          left={`${cell.left! * 100}%`}
                          top={`${cell.top! * 100}%`}
                          right={`${cell.right! * 100}%`}
                          bottom={`${cell.bottom! * 100}%`}
                          backgroundColor={bgColor}
                          transform={`skew(${cell.skewXAxis!}deg)`}
                        ></Box>
                      )
                    : cells.push(
                        <Box
                          key={ind}
                          gridColumn={`span ${cell.colSpan} / auto`}
                          gridRow={`span ${cell.rowSpan} / auto`}
                          backgroundColor={bgColor}
                          borderRadius="sm"
                        />
                      );
                });

                const items = box.template.cells.reduce((p, c) => p + c.content.length, 0);

                return (
                  <Tr color="gray.600" key={i}>
                    <Td w="100px">
                      {!box.template.coordinateBasedLayout ? (
                        <Box className="storage-box-grid-wrapper">
                          <Box className={box.template.name} rowGap="1px" columnGap="1px">
                            {cells}
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          w="125px"
                          className={box.template.name}
                          position="relative"
                          style={{ aspectRatio: box.template.aspectRatio ?? defaultExtractionToolsBoxAspectRatio }}
                        >
                          <Image src={box.template.image} />
                          {cells}
                        </Box>
                      )}
                    </Td>
                    <Td>
                      <Box as={RouterLink} to={`/preview/${box.id}`}>
                        <Text color="chakra-body-text" fontWeight="semibold">
                          {box.label}
                        </Text>
                        <Text fontSize="sm">{items === 1 ? `${items} item` : `${items} items`}</Text>
                      </Box>
                    </Td>
                    <Td>
                      <RouterLink to={`/location/${name}`}>{locationName}</RouterLink>
                    </Td>
                    <Td>
                      {new Intl.DateTimeFormat("en-SE", {
                        dateStyle: "long",
                        timeStyle: "short",
                      }).format(new Date(box.startingDate))}
                    </Td>
                    <Td>
                      {new Intl.DateTimeFormat("en-SE", { dateStyle: "long", timeStyle: "short" }).format(
                        new Date(box.finishDate)
                      )}
                    </Td>
                    <Td textAlign="right">
                      <ButtonGroup variant="tertiary">
                        <Tooltip label="Preview">
                          <IconButton
                            isDisabled={pendingBoxDeletionIndex !== undefined}
                            as={RouterLink}
                            to={`/preview/${box.id}`}
                            aria-label="Preview"
                            icon={<MdVisibility />}
                          />
                        </Tooltip>
                        <Tooltip label="Edit">
                          <IconButton
                            isDisabled={pendingBoxDeletionIndex !== undefined}
                            as={RouterLink}
                            to={`/edit/${box.id}`}
                            aria-label="Edit"
                            icon={<MdEdit />}
                          />
                        </Tooltip>
                        <Tooltip label="Delete">
                          <IconButton
                            aria-label="Delete"
                            icon={<MdDelete />}
                            isDisabled={pendingBoxDeletionIndex !== undefined}
                            onClick={() => {
                              setPendingBoxDeletionIndex(i);
                              onOpen();
                            }}
                            isLoading={i === pendingBoxDeletionIndex}
                          />
                        </Tooltip>
                      </ButtonGroup>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Loading />
      )}
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setPendingBoxDeletionIndex(undefined);
          onClose();
        }}
        initialFocusRef={modalRef}
        returnFocusOnClose={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottomWidth={1}>
            <ModalCloseButton />
            <Heading fontSize="lg">Delete box</Heading>
          </ModalHeader>
          <ModalBody pb={4} my={2}>
            <Text>Are you sure you want to delete this box?</Text>
          </ModalBody>
          <ModalFooter bg="gray.100" borderTop={1} borderBottomRadius="lg">
            <Flex w="full">
              <Flex ml="auto" gap={2}>
                <Button
                  variant="tertiary"
                  onClick={() => {
                    setPendingBoxDeletionIndex(undefined);
                    onClose();
                  }}
                >
                  Cancel
                </Button>

                <Button
                  variant="negative"
                  ref={modalRef}
                  leftIcon={<Icon as={MdDelete} />}
                  onClick={() => {
                    const boxToDelete = boxes?.find((box, i) => i === pendingBoxDeletionIndex);
                    if (boxToDelete) {
                      deleteBox(boxToDelete.id).then(() => setPendingBoxDeletionIndex(undefined));
                      onClose();
                    }
                  }}
                >
                  Delete
                </Button>
              </Flex>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default Location;
