import { FilterType, Node, NodeWithEdges, RequestParams } from "../interfaces";
import Setting from "../lib/setting";
import { pdbBaseEndpoint } from "../Environment";

const _getHeaders = (): Headers => {
  const token = localStorage.getItem("token") ?? "";

  return new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  });
};

const getSimpleProductList = async (
  maxFetchLimit: number,
  searchString: string,
  page: number,
  onlyArticleNumber = true,
  abortController?: AbortController,
  searchKey = "manufacturer_article_number"
): Promise<Node[]> => {
  const keySetting = new Setting("article_number_key");
  let searchFilter: FilterType = {
    OR: [
      [keySetting.getStoredValue(), "ILIKE", `%${searchString}%`],
      [searchKey, "ILIKE", `%${searchString.trim()}%`],
    ],
  };

  if (onlyArticleNumber) {
    searchFilter = {
      AND: [searchFilter, [keySetting.getStoredValue(), "!=", ``]],
    };
  }

  const url =
    "nodes/?q=" +
    encodeURIComponent(JSON.stringify(searchFilter)) +
    "&skip=" +
    Math.max(0, maxFetchLimit * (page - 1)) +
    "&limit=" +
    maxFetchLimit;

  const res = await makeRequest({ url, method: "get" }, abortController);

  return res as Node[];
};

const getProduct = (id: any, abortController?: AbortController): Promise<NodeWithEdges | undefined> =>
  makeRequest({ url: "nodes/" + id, method: "get" }, abortController);

const makeRequest = async ({ url, method, body }: RequestParams, abortController?: AbortController) => {
  const requestBody: RequestInit = {
    headers: _getHeaders(),
    method,
    signal: abortController?.signal,
  };

  if (body) {
    requestBody.body = JSON.stringify(body);
  }

  const paramSymbol = url.includes("?") ? "&" : "?";

  const res = await fetch(
    pdbBaseEndpoint + url + paramSymbol + "api_key=pCjWEuB8drUnFlEJoSkbFfdTO7_OCQfMf2Lc-5-lJNY",
    requestBody
  );

  if (!res.ok) {
    return;
  }

  return await res.json();
};

export const ProductDatabaseConnection = {
  getSimpleProductList,
  getProduct,
};
