import { Flex, Spinner } from "@wiretronic/iris";

const Loading = (): JSX.Element => {
  return (
    <Flex w="full" h="full" align="center" justify="center" bg="white">
      <Spinner size={"xl"} />
    </Flex>
  );
};

export default Loading;
