import { ButtonGroup, Center, HStack, IconButton } from "@wiretronic/iris";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

type PaginationProps = {
  page: number;
  shiftPage: (by: number) => void;
  isLoading: boolean;
};

export const Pagination = (props: PaginationProps) => {
  const renderPagination = () => {
    if (props.isLoading) {
      return;
    }
    return (
      <ButtonGroup>
        <IconButton
          icon={<MdChevronLeft />}
          variant="tertiary"
          aria-label="Previous page"
          onClick={() => {
            props.shiftPage(-1);
          }}
        />

        <Center>{props.page}</Center>
        <IconButton
          icon={<MdChevronRight />}
          variant="tertiary"
          aria-label="Previous page"
          onClick={() => {
            props.shiftPage(1);
          }}
        />
      </ButtonGroup>
    );
  };
  return <HStack justifyContent={"center"}>{renderPagination()}</HStack>;
};
