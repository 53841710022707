import React, { createContext } from "react";
import useLocalStorageState from "./hooks/useLocalStorageState";

export const UserContext = createContext<{
  userName: string | null;
  token: string | null;
  setUser: (userName: string, token: string) => void;
  logout: () => void;
}>({
  userName: null,
  token: null,
  setUser: () => {},
  logout: () => {},
});

export default function UserProvider({ children }: any) {
  const [token, setToken] = useLocalStorageState("token");
  const [userName, setUserName] = useLocalStorageState("userName");

  const setUser = (userName: string, token: string) => {
    setUserName(userName);
    setToken(token);
  };

  const logout = () => setToken(null);
  return <UserContext.Provider value={{ token, userName, setUser, logout }}>{children}</UserContext.Provider>;
}
