import {
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@wiretronic/iris";
import React, { useRef } from "react";
import "../styles/BoxTemplates.css";
import "../styles/content.css";
import data from "../templates.json";
import { Template, Cell } from "./BoxEditorAndViewerFunctionality";
import { defaultExtractionToolsBoxAspectRatio } from "./Location";

interface Props {
  isOpen: boolean;
  onClose(): void;
  largerViewInfo: { img: string; artNr: string };
}

function ViewProductModal({ isOpen, onClose, largerViewInfo }: Props) {
  const isTemplateInView = useRef(false);

  const generatePreviewGrid = (templateName: string) => {
    const cells: React.ReactElement[] = [];
    const template = data["Templates"].filter((template: Template) => {
      return template.name === templateName;
    });
    if (template.length === 0) {
      isTemplateInView.current = false;
      return <></>;
    }
    isTemplateInView.current = true;
    template[0].cells.forEach((cell: Cell, i) => {
      template[0].coordinateBasedLayout
        ? cells.push(
            <Box
              key={i}
              position="absolute"
              left={`${cell.left! * 100}%`}
              top={`${cell.top! * 100}%`}
              right={`${cell.right! * 100}%`}
              bottom={`${cell.bottom! * 100}%`}
              transform={`skew(${cell.skewXAxis!}deg)`}
              background="gray.200"
            ></Box>
          )
        : cells.push(
            <Box
              borderRadius="sm"
              background="gray.200"
              key={i}
              gridColumn={`span ${cell.colSpan} / auto`}
              gridRow={`span ${cell.rowSpan} / auto`}
            />
          );
    });
    return template[0].coordinateBasedLayout ? (
      <Box
        w="%"
        className={template[0].name}
        position="relative"
        style={{ aspectRatio: template[0].aspectRatio ?? defaultExtractionToolsBoxAspectRatio }}
      >
        {cells}
      </Box>
    ) : (
      <Box style={{ height: "220px" }} className={largerViewInfo.artNr} gap={1}>
        {cells}
      </Box>
    );
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{largerViewInfo.artNr}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <img src={largerViewInfo.img} alt={largerViewInfo.artNr} />
            <Heading size="lg" mb={4}>
              Layout
            </Heading>
            {generatePreviewGrid(largerViewInfo.artNr)}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ViewProductModal;
