import { Card, CardBody, CardFooter, CardHeader, Divider } from "@chakra-ui/react";
import { Box, Flex, Icon } from "@wiretronic/iris";
import "../../styles/content.css";

import { Button, Heading } from "@wiretronic/iris";
import React from "react";
import { MdAddCircle, MdDelete } from "react-icons/md";
import { Template } from "../BoxEditorAndViewerFunctionality";
import CellContentListitem from "./CellContentListItem";

interface Props {
  activeCell: number | undefined;
  setIsInCellEditMode(val: boolean): void;
  deleteCellContentEntry(ind: number | undefined): void;
  changeQuantity(index: number, updateFn: (old: number) => number): void;
  itemSearch: string;
  handleItemSearch(e: React.ChangeEvent<HTMLInputElement>): void;
  checkContains(): void;
  onOpen(): void;
  _setLargerViewInfo(img: string, artNr: string): void;
  onOpenProductModal(): void;
  template: Template;
}

function ViewCellContentPane({
  activeCell,
  deleteCellContentEntry,
  changeQuantity,
  itemSearch,
  handleItemSearch,
  checkContains,
  onOpen,
  _setLargerViewInfo,
  onOpenProductModal,
  template,
  setIsInCellEditMode,
}: Props) {
  const RenderCellContent = () => {
    if (activeCell === undefined) {
      return [];
    }
    const cellContentList = template.cells[activeCell].content?.map((elem, index) => {
      return (
        <CellContentListitem
          key={index}
          elem={elem}
          index={index}
          deleteCellContentEntry={deleteCellContentEntry}
          changeQuantity={changeQuantity}
          _setLargerViewInfo={_setLargerViewInfo}
          onOpenProductModal={onOpenProductModal}
        />
      );
    });
    return cellContentList;
  };

  const itemsCount = activeCell !== undefined ? template.cells[activeCell].content.length : 0;

  return (
    <Box pb={4}>
      <Card>
        <CardHeader>
          <Flex w="full" align="flex-start" justify="space-between">
            <Heading size="md">
              {template.name === ""
                ? "Enter a box"
                : activeCell !== undefined
                ? itemsCount === 1
                  ? `${itemsCount} item`
                  : `${itemsCount} items`
                : "Select a cell"}
            </Heading>
            {activeCell !== undefined && (
              <Button
                variant="outline"
                leftIcon={<MdAddCircle />}
                disabled={activeCell === undefined}
                onClick={() => setIsInCellEditMode(true)}
              >
                Add item
              </Button>
            )}
          </Flex>
        </CardHeader>
        <CardBody mt={4}>{RenderCellContent()}</CardBody>
        <Divider />
        <CardFooter>
          {activeCell !== undefined && template.cells[activeCell].content.length !== 0 && (
            <Button
              w="full"
              mt={4}
              variant="outline"
              onClick={() => {
                if (activeCell !== undefined && template.cells[activeCell!].content.length !== 0) {
                  onOpen();
                }
              }}
              leftIcon={<Icon as={MdDelete} color="red.700" />}
            >
              Empty cell
            </Button>
          )}
        </CardFooter>
      </Card>
    </Box>
  );
}

export default ViewCellContentPane;
