import { IrisProvider } from "@wiretronic/iris";
import UserProvider from "./User";
import Router from "./Router";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <IrisProvider>
      <UserProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </UserProvider>
    </IrisProvider>
  );
}

export default App;
